import React from 'react'
import RecentlySold from './RecentlySold'
import TopRated from './TopRated'
import NewArrivals from './NewArrivals'

const ThreeCarouseMain = () => {
    return (
        <div className="container mx-auto px-4 mt-18 mb-18">
            <div className="md:pr-7 grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-8">
                <RecentlySold />
                <TopRated />
                <NewArrivals />
            </div>
        </div>
    )
}

export default ThreeCarouseMain
