import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'

const BrandFiltering = () => {
    return (
        <div className="brandFiltering">
            <p className="font-Poppins font-semibold text-base text-topBarTextColor mb-2.75">Brand</p>
            <hr className="border-t-2 mb-4.5" />
            <Scrollbars style={{ height: 280 }}>
                <p className="font-DMSans text-sm text-logobarElementBG font-bold mb-3.25"><span>Adidas</span></p>
                <p className="font-DMSans text-sm text-sliderDescription mb-3.25"><span>Puma</span></p>
                <p className="font-DMSans text-sm text-sliderDescription mb-3.25"><span>Nike</span></p>
                <p className="font-DMSans text-sm text-sliderDescription mb-3.25"><span>Asics</span></p>
                <p className="font-DMSans text-sm text-sliderDescription mb-3.25"><span>Bata</span></p>
                <p className="font-DMSans text-sm text-sliderDescription mb-3.25"><span>Apex</span></p>
                <p className="font-DMSans text-sm text-sliderDescription mb-3.25"><span>Lotto</span></p>
                <p className="font-DMSans text-sm text-sliderDescription mb-3.25"><span>Bay</span></p>
                <p className="font-DMSans text-sm text-sliderDescription mb-3.25"><span>Puca</span></p>
                <p className="font-DMSans text-sm text-sliderDescription"><span>Weinbrenner</span></p>
            </Scrollbars>
        </div>
    )
}

export default BrandFiltering
