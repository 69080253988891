import { createSlice } from "@reduxjs/toolkit";

export const logInSlice = createSlice({
    name: "logInSystem",

    initialState: {
        token: null,
        mobileNumber: null
    },

    reducers: {
        savingTokenValue: (state, action) => {
            state.token = action.payload
        },
        savingMobileNumber: (state, action) => {
            state.mobileNumber = action.payload
        },
        savingTokenInLocalStorage: (state, action) => {
            localStorage.setItem("FFtoken", JSON.stringify(action.payload))
        },
    }
})

export const { savingTokenValue, savingMobileNumber, savingTokenInLocalStorage } = logInSlice.actions

export default logInSlice.reducer