import React from 'react'

const MotherMenu = () => {
    return (
        <div className="container mx-auto px-4 bg-white h-95" style={{ boxShadow: "0px 20px 46px 0 rgba(0, 0, 0, 0.15)" }}>
            <div className="grid gap-8 grid-cols-6 px-5">
                <div className="mt-7.75 mb-7.75">
                    <p className="font-Poppins font-bold text-topBarTextColor text-base">Mother's Care</p>
                    <p className="mt-5"><span className="menu-text">Maternity Clothes</span></p>
                    <p className="mt-3"><span className="menu-text">Pregnancy Essentials</span></p>
                </div>
                <div className="mt-7.75">
                    <p className="font-Poppins font-bold text-topBarTextColor text-base">Baby's Care</p>
                    <p className="mt-5"><span className="menu-text">Baby Toiletries</span></p>
                    <p className="mt-3"><span className="menu-text">Baby Feeds</span></p>
                    <p className="mt-3"><span className="menu-text">Skin Care</span></p>
                    <p className="mt-3"><span className="menu-text">Hair Care</span></p>
                    <p className="mt-3"><span className="menu-text">Potty Training</span></p>
                    <p className="mt-3"><span className="menu-text">Health & Safety</span></p>
                    <p className="mt-3"><span className="menu-text">Baby Grooming</span></p>
                    <p className="mt-3"><span className="menu-text">Bathing Accessories</span></p>
                </div>
                <div className="mt-7.75">
                    <p className="font-Poppins font-bold text-topBarTextColor text-base">Nursing & Feeding</p>
                    <p className="mt-5"><span className="menu-text">Baby Feeding Bottle</span></p>
                    <p className="mt-3"><span className="menu-text">Nipple</span></p>
                    <p className="mt-3"><span className="menu-text">Water Bottle, Flask & Glass</span></p>
                    <p className="mt-3"><span className="menu-text">Teether, Pacifier & Spoon</span></p>
                    <p className="mt-3"><span className="menu-text">Baby Plate, Bowl & Jar</span></p>
                </div>
                <div className="mt-7.75">
                    <p className="font-Poppins font-bold text-topBarTextColor text-base">Baby Food</p>
                    <p className="mt-5"><span className="menu-text">Baby Cereal</span></p>
                    <p className="mt-3"><span className="menu-text">Baby Milk</span></p>
                </div>
                <div className="mt-7.75">

                </div>
                <div className="mt-7.75">

                </div>
            </div>
        </div>
    )
}

export default MotherMenu
