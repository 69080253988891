import React, { useState, useEffect } from 'react'
import { useSelector } from "react-redux"

const CartButton = props => {
    const { setCartSideBar } = props

    const { cartItem } = useSelector(state => state.testCart)

    return (
        <div
            className="w-12.5 h-12.5 flex justify-center bg-white items-center fixed bottom-20 right-8 rounded-3xl cursor-pointer cart"
            onClick={() => setCartSideBar(prevState => !prevState)}
            style={{ boxShadow: "0px 0px 25px -10px #000000" }}
        >
            <div className="relative h-full w-full flex justify-center items-center">
                <i className="far fa-shopping-bag" style={{ fontSize: 20 }}></i>

                <div className="absolute w-6 h-6 bg-logobarElementBG flex justify-center items-center -top-2 -right-2" style={{ borderRadius: "50%" }}>
                    <p className="text-white font-Poppins font-bold text-xs">{cartItem}</p>
                </div>
            </div>
        </div>
    )
}

export default CartButton
