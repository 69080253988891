import React, { useState, useRef } from 'react'
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import Tippy from "@tippyjs/react"
import 'tippy.js/dist/tippy.css';
import LinesEllipsis from "react-lines-ellipsis"
import { BiPlus, BiMinus } from "react-icons/bi"
import { FaFacebookF, FaTwitter, FaInstagram, FaPinterestP, FaWhatsapp } from "react-icons/fa"

import ProductImage1 from "../../Assets/Images/product-image1.png"
import ProductImage2 from "../../Assets/Images/product-image2.png"
import ProductImage3 from "../../Assets/Images/product-image3.png"
import ProductImage4 from "../../Assets/Images/product-image4.png"

import Slider from "react-slick";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, height: 30, width: 30, borderRadius: 15, background: "#f6f6f6", display: "flex", justifyContent: "center", alignItems: "center", color: "#999999" }}
            onClick={onClick}
        >
            <div style={{ fontSize: 17 }} className="custom-arrow-icon-div">
                <i className="fas fa-angle-right custom-arrow-icon"></i>
            </div>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, height: 30, width: 30, borderRadius: 15, background: "#f6f6f6", display: "flex", justifyContent: "center", alignItems: "center", color: "#999999" }}
            onClick={onClick}
        >
            <div style={{ fontSize: 17 }} className="custom-arrow-icon-div">
                <i className="fas fa-angle-left custom-arrow-icon"></i>
            </div>
        </div>
    );
}

const TestModal = props => {

    const { modal, setModal, data, indexValue } = props

    console.log(data)

    const selectedProduct = data[indexValue]
    console.log(selectedProduct)
    const modalRef = useRef()

    // useEffect(() => {
    //     // window.scrollTo({ behavior: "smooth", top: modalRef.current.offsetTop })
    //     // console.log(modalRef)
    //     modalRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    // }, [])


    const closeModal = () => {
        setModal(false)
    }

    const images = [ProductImage1, ProductImage2, ProductImage3, ProductImage4]

    const settings = {
        customPaging: function (i) {
            return (
                <div className="w-3/4 h-3/4">
                    <img src={images[i]} alt="Products Demo" className="h-full w-full" />
                </div>
            );
        },
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    const [numberOfProduct, setNumberOfProduct] = useState(0)



    return (
        <div>
            <Modal open={modal} onClose={closeModal} center={true} blockScroll={false} ref={modalRef}>
                <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
                    <div className="modalCarousel">
                        <Slider {...settings} className="h-140">
                            <div>
                                <div className="h-106 bg-topBarBG flex justify-center items-center">
                                    <img src={ProductImage1} alt="Products" />
                                </div>
                            </div>
                            <div>
                                <div className="h-106 bg-topBarBG flex justify-center items-center">
                                    <img src={ProductImage2} alt="Products" />
                                </div>
                            </div>
                            <div>
                                <div className="h-106 bg-topBarBG flex justify-center items-center">
                                    <img src={ProductImage3} alt="Products" />
                                </div>
                            </div>
                            <div>
                                <div className="h-106 bg-topBarBG flex justify-center items-center">
                                    <img src={ProductImage4} alt="Products" />
                                </div>
                            </div>
                        </Slider>
                    </div>
                    <div>
                        <p className="text-xl text-topBarTextColor font-Poppins font-semibold hover:text-logobarElementBG">{selectedProduct.name}</p>
                        <p className="font-DMSans text-topBarTextColor text-3.25 mt-2">Category: <span className="text-mutedText hover:text-logobarElementBG">Women</span></p>
                        <p className="font-DMSans text-topBarTextColor text-3.25 mt-1">Brand: <span className="text-mutedText hover:text-logobarElementBG">Fashion Field</span></p>
                        <hr className="mt-3 mb-3" />
                        <p className="font-Poppins font-bold text-topBarTextColor text-2xl">TK. {selectedProduct.newPrice}</p>
                        <div className="flex items-center mt-1">
                            <Tippy content="4.00">
                                <div>
                                    <i className="fas fa-star" style={{ fontSize: 13 }}></i>
                                    <i className="fas fa-star" style={{ fontSize: 13 }}></i>
                                    <i className="fas fa-star" style={{ fontSize: 13 }}></i>
                                    <i className="fas fa-star" style={{ fontSize: 13 }}></i>
                                    <i className="far fa-star" style={{ fontSize: 13 }}></i>
                                </div>
                            </Tippy>
                            <div className="ml-2 mt-1">
                                <p className="font-Poppins text-mutedText text-3.25">3 Reviews</p>
                            </div>
                        </div>
                        <LinesEllipsis
                            text="Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, Lorem ipsum dolor sit amet.., comes from a line in section 1.10.32"
                            maxLine="5"
                            ellipsis="..."
                            className="font-DMSans text-topBarTextColor text-3.25 mt-2"
                        />
                        <hr className="mt-3 mb-3" />
                        <div className="flex items-center mt-2">
                            <p className="font-DMSans text-topBarTextColor text-sm">Color: </p>
                            <div className="flex ml-8">
                                <div className="h-6 w-6 xs:h-8 xs:w-8 mr-4" onClick={e => { console.log(e.currentTarget.style.backgroundColor) }}>
                                    <img src={ProductImage1} className="h-full w-full" alt="Products" />
                                </div>
                                <div className="h-6 w-6 xs:h-8 xs:w-8 mr-4">
                                    <img src={ProductImage2} className="h-full w-full" alt="Products" />
                                </div>
                                <div className="h-6 w-6 xs:h-8 xs:w-8 mr-4">
                                    <img src={ProductImage3} className="h-full w-full" alt="Products" />
                                </div>
                                <div className="h-6 w-6 xs:h-8 xs:w-8">
                                    <img src={ProductImage4} className="h-full w-full" alt="Products" />
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row md:items-center mt-6">
                            <p className="font-DMSans text-topBarTextColor text-sm">Size: </p>
                            <div className="flex md:ml-10 mt-2 md:mt-0">
                                <p className="font-DMSans text-topBarTextColor border-1 px-3 xs:px-3.5 md:px-4 py-1 mr-2 hover:border-logobarElementBG hover:text-logobarElementBG cursor-pointer text-sm">S</p>
                                <p className="font-DMSans text-topBarTextColor border-1 px-3 xs:px-3.5 md:px-4 py-1 mr-2 hover:border-logobarElementBG hover:text-logobarElementBG cursor-pointer text-sm">M</p>
                                <p className="font-DMSans text-topBarTextColor border-1 px-3 xs:px-3.5 md:px-4 py-1 mr-2 hover:border-logobarElementBG hover:text-logobarElementBG cursor-pointer text-sm">L</p>
                                <p className="font-DMSans text-topBarTextColor border-1 px-3 xs:px-3.5 md:px-4 py-1 mr-2 hover:border-logobarElementBG hover:text-logobarElementBG cursor-pointer text-sm">XL</p>
                                <p className="font-DMSans text-topBarTextColor border-1 px-3 xs:px-3.5 md:px-4 py-1 hover:border-logobarElementBG hover:text-logobarElementBG cursor-pointer text-sm">XXL</p>
                            </div>
                        </div>

                        <div className="grid grid-cols-12 gap-4 h-11 mt-8">
                            <div className="col-span-5 md:col-span-4 border-1 rounded flex items-center">
                                <div className="w-1/2 pl-3">
                                    {numberOfProduct}
                                </div>
                                <div className="w-1/2 flex justify-between mr-2 items-center">
                                    <div className="w-9 h-7 bg-topBarBG mr-2 flex items-center justify-center cursor-pointer" style={{ borderRadius: "50%" }} onClick={() => setNumberOfProduct(prevState => prevState + 1)}>
                                        <BiPlus fontSize={18} color="#252525" />
                                    </div>
                                    <div className="w-9 h-7 bg-topBarBG flex items-center justify-center cursor-pointer" style={{ borderRadius: "50%" }} onClick={() => setNumberOfProduct(prevState => prevState - 1)}>
                                        <BiMinus fontSize={18} color="#252525" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-3 xs:col-span-2 border-1 hover:bg-logobarElementBG rounded flex justify-center items-center group modal-heart">
                                <i className="far fa-heart group-hover:text-white text-lg md:text-2xl"></i>
                            </div>
                            <div className="col-span-4 xs:col-span-5 md:col-span-6 bg-logobarElementBG rounded flex justify-center items-center">
                                <i className="far fa-shopping-bag md:mr-2 xs:hidden md:block" style={{ color: "white" }}></i>
                                <p className="text-white font-semibold font-Poppins text-base mt-1 hidden xs:block">Add to Bag</p>
                            </div>
                        </div>


                        <div className="flex mt-8">
                            <button className="w-10 h-10 border-1 border-topBarVerticalSeperator rounded-2.5xl flex justify-center items-center mr-2.5 hover:bg-logobarElementBG hover:text-white text-topBarTextColor">
                                <FaFacebookF />
                            </button>
                            <button className="w-10 h-10 border-1 border-topBarVerticalSeperator rounded-2.5xl flex justify-center items-center mr-2.5 hover:bg-logobarElementBG hover:text-white text-topBarTextColor">
                                <FaTwitter />
                            </button>
                            <button className="w-10 h-10 border-1 border-topBarVerticalSeperator rounded-2.5xl flex justify-center items-center mr-2.5 hover:bg-logobarElementBG hover:text-white text-topBarTextColor">
                                <FaWhatsapp />
                            </button>
                            <button className="w-10 h-10 border-1 border-topBarVerticalSeperator rounded-2.5xl flex justify-center items-center mr-2.5 hover:bg-logobarElementBG hover:text-white text-topBarTextColor">
                                <FaInstagram />
                            </button>
                            <button className="w-10 h-10 border-1 border-topBarVerticalSeperator rounded-2.5xl flex justify-center items-center hover:bg-logobarElementBG hover:text-white text-topBarTextColor">
                                <FaPinterestP />
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
export default TestModal
