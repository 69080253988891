import React from 'react'
import { Link } from "react-router-dom"

const MenMenu = props => {
    const { setDisplayMen, hidden } = props
    return (
        <div className="container mx-auto px-4 bg-white h-96" style={{ boxShadow: "0px 20px 46px 0 rgba(0, 0, 0, 0.15)" }}>
            <div className="grid gap-8 grid-cols-6 px-5">
                <div className="mt-7.75 mb-7.75">
                    <p className="font-Poppins font-bold text-topBarTextColor text-base">Men's Clothing</p>
                    <p className="mt-5"><span className="menu-text">Men's Shirts</span></p>
                    <p className="mt-3"><span className="menu-text">Men's T-Shirts</span></p>
                    <p className="mt-3"><span className="menu-text">Men's Polo Shirt</span></p>
                    <p className="mt-3"><span className="menu-text">Men's Ethnic</span></p>
                    <p className="mt-3"><span className="menu-text">Men's Pants</span></p>
                    <p className="mt-3"><span className="menu-text">Men's Innerwear</span></p>
                    <p className="mt-3"><span className="menu-text">Men's Tank Top</span></p>
                    <p className="mt-3"><span className="menu-text">Men's Winter</span></p>
                </div>
                <div className="mt-7.75">
                    <p className="font-Poppins font-bold text-topBarTextColor text-base">Men's Watches</p>
                    <p className="mt-5"><span className="menu-text">Original Watches</span></p>
                    <p className="mt-3"><span className="menu-text">Smart Watches</span></p>
                </div>
                <div className="mt-7.75">
                    <p className="font-Poppins font-bold text-topBarTextColor text-base">Men's Footwear</p>
                    <p className="mt-5"><span className="menu-text">Sandals</span></p>
                    <p className="mt-3"><span className="menu-text">Sports Sandals</span></p>
                    <p className="mt-3"><span className="menu-text">Formal Shoes</span></p>
                    <p className="mt-3"><span className="menu-text">Casual Shoes</span></p>
                    <Link className="mt-3 inline-block" to="/category/running-shoe"><span className="menu-text" onClick={() => setDisplayMen(hidden)}>Running Shoes</span></Link>
                    <p className="mt-3"><span className="menu-text">Converse/Sneakers</span></p>
                    <p className="mt-3"><span className="menu-text">Boots</span></p>
                    <p className="mt-3"><span className="menu-text">Loafers</span></p>
                </div>
                <div className="mt-7.75">
                    <p className="font-Poppins font-bold text-topBarTextColor text-base">Men's Care</p>
                    <p className="mt-5"><span className="menu-text">Men's Perfume</span></p>
                    <p className="mt-3"><span className="menu-text">Men's Deodorant</span></p>
                    <p className="mt-3"><span className="menu-text">Skin Care</span></p>
                    <p className="mt-3"><span className="menu-text">Hair Care</span></p>
                    <p className="mt-3"><span className="menu-text">Body Care</span></p>
                    <p className="mt-3"><span className="menu-text">Shaving & Grooming Needs</span></p>
                </div>
                <div className="mt-7.75">
                    <p className="font-Poppins font-bold text-topBarTextColor text-base">Men's Accessories</p>
                    <p className="mt-5"><span className="menu-text">Belts</span></p>
                    <p className="mt-3"><span className="menu-text">Wallets</span></p>
                    <p className="mt-3"><span className="menu-text">Sunglasses</span></p>
                    <p className="mt-3"><span className="menu-text">Hats & Caps</span></p>
                    <p className="mt-3"><span className="menu-text">Ties & Bow Ties</span></p>
                    <p className="mt-3"><span className="menu-text">Bags</span></p>
                </div>
                <div className="mt-7.75">

                </div>
            </div>
        </div>
    )
}

export default MenMenu
