import React, { useState, forwardRef, useRef, useImperativeHandle } from 'react'
import Slider from "react-slick";
import ProgressBar from "@ramonak/react-progress-bar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tippy from "@tippyjs/react"
import 'tippy.js/dist/tippy.css';

import "./carousel.css"


import TestModal from './TestModal';

import { useDispatch } from "react-redux"
import { addingProduct, calculatingTotalPrice, calculatingTotalCartProducts } from "../../ReduxStore/CartSystem/CartStore"
import axios from 'axios';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, height: 30, width: 30, borderRadius: 15, background: "#eeeeee", display: "flex", justifyContent: "center", alignItems: "center", color: "#999999" }}
            onClick={onClick}
        >
            <div style={{ fontSize: 17 }} className="custom-arrow-icon-div">
                <i className="fas fa-angle-right custom-arrow-icon"></i>
            </div>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    // console.log(props)
    return (
        <div
            className={className}
            style={{ ...style, height: 30, width: 30, borderRadius: 15, background: "#eeeeee", display: "flex", justifyContent: "center", alignItems: "center", color: "#999999" }}
            onClick={onClick}
        >
            <div style={{ fontSize: 17 }} className="custom-arrow-icon-div">
                <i className="fas fa-angle-left custom-arrow-icon"></i>
            </div>
        </div>
    );
}


const Carousel = forwardRef((props, forwardedRef) => {

    const { data, carouselType, slidesToShow, arrows, laptop, tab, mobile, dots, autoplay, centerMode, mobileArrow, } = props


    const commonCarouselRef = useRef(null)

    useImperativeHandle(forwardedRef, () => {
        return {
            nextButton: () => {
                commonCarouselRef.current.slickNext()
            },
            prevButton: () => {
                commonCarouselRef.current.slickPrev()
            }
        }
    })

    const settings = {
        centerMode: centerMode,
        dots: dots,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        arrows: arrows,
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => (
            <div className="ft-slick__dots--custom">
            </div>
        ),
        autoplay: autoplay,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: laptop,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: dots,
                    arrows: false,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: tab,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: mobile,
                    slidesToScroll: 1,
                    arrows: mobileArrow,
                }
            }
        ]
    }
    const [modal, setModal] = useState(false)
    const [indexValue, setIndex] = useState(null)
    // const [scale, setScale] = useState("scale-101")

    const commonCarouselQuickViewHandle = (item, index) => {
        setModal(true)
        setIndex(index)

    }

    const dispatch = useDispatch()

    const handleAddingProductInCart = (item) => {
        // console.log(item)
        dispatch(addingProduct(item))
        dispatch(calculatingTotalPrice())
        dispatch(calculatingTotalCartProducts())
    }



    return (
        <>
            {
                carouselType === "mainCarousel" && <Slider {...settings}>
                    {
                        data.map((item, index) => (
                            <div className="focus:outline-none" key={index}>
                                <img src={item.image} alt="Hero Slider" />
                            </div>
                        ))
                    }
                </Slider>
            }

            {
                carouselType === "dealsOfTheDay" && <Slider {...settings}>
                    {
                        data.map((item, index) => (
                            <div key={index} >
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-8 md:pr-7">
                                    <div className="flex h-82.5 justify-center bg-topBarBG border-1 hover:border-mutedText border-transparent hover:shadow-md transition-all ease-linear duration-300 relative">
                                        <div className="w-67 h-full flex justify-center items-center bg-topBarBG relative overflow-hidden">
                                            <div className="transform scale-101 hover:scale-102 transition-all ease-linear duration-300 flex items-center">
                                                <img src={item.image} alt="Deals of the Day Slider" />
                                            </div>
                                        </div>
                                        <div className="w-9 h-9 bg-logobarElementBG absolute rounded-4.5 flex justify-center items-center top-4 right-4">
                                            <p className="font-Poppins text-white text-tinyxl font-semibold">{item.discount}%</p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col h-83 px-2 md:px-0">
                                        <p className="text-sliderText font-Poppins font-semibold text-base hover:text-logobarElementBG">{item.name}</p>
                                        <div className="flex items-center mb-7">
                                            <strike className="text-mutedText font-Poppins font-semibold text-xs mr-2">TK.{item.previousPrice}</strike>
                                            <p className="font-Poppins font-semibold text-logobarElementBG text-lg">TK.{item.newPrice}</p>
                                        </div>
                                        <p className="font-DMSans text-sm text-sliderDescription">{item.description}</p>
                                        <div className="mt-auto">
                                            <div className="flex mb-2">
                                                <p className="font-DMSans text-sm font-semibold">Available: <span className="text-logobarElementBG">{item.available}</span></p>
                                                <p className="font-DMSans text-sm font-semibold ml-auto">sold: <span className="text-logobarElementBG">{item.sold}</span></p>
                                            </div>
                                            <ProgressBar completed={item.available} bgColor="#e5371b" baseBgColor="#eeeeee" isLabelVisible={false} height="12px" />
                                            <div className="flex mt-5 items-center">
                                                <div>
                                                    <p className="font-DMSans text-xl text-sliderText font-semibold">Hurry Up!</p>
                                                    <p className="font-DMSans text-xs text-sliderText font-semibold">Offer ends in</p>
                                                </div>
                                                <div className="ml-auto">
                                                    <div className="flex">
                                                        <div className="flex flex-col items-center mr-2">
                                                            <div className="w-9 h-9 bg-timeBG flex justify-center items-center rounded-2xl">
                                                                <p className="font-Poppins font-semibold text-sliderText text-xs">{item.hours}</p>
                                                            </div>
                                                            <p className="font-Poppins font-medium text-tiny text-sliderDescription mt-2">HOURS</p>
                                                        </div>
                                                        <div className="flex flex-col items-center mr-2">
                                                            <div className="w-9 h-9 bg-timeBG flex justify-center items-center rounded-2xl">
                                                                <p className="font-Poppins font-semibold text-sliderText text-xs">{item.mins}</p>
                                                            </div>
                                                            <p className="font-Poppins font-medium text-tiny text-sliderDescription mt-2">MINS</p>
                                                        </div>
                                                        <div className="flex flex-col items-center">
                                                            <div className="w-9 h-9 bg-timeBG flex justify-center items-center rounded-2xl">
                                                                <p className="font-Poppins font-semibold text-sliderText text-xs">{item.secs}</p>
                                                            </div>
                                                            <p className="font-Poppins font-medium text-tiny text-sliderDescription mt-2">SECS</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </Slider>
            }

            {
                carouselType === "commonCarousel" && <div>
                    <Slider {...settings} ref={commonCarouselRef}>
                        {
                            data.map((item, index) => (
                                <div key={index}>
                                    <div className="flex justify-center md:justify-start transition-all ease-linear duration-300">
                                        <div className="w-67">
                                            <div className="w-full h-83 flex justify-center items-center bg-topBarBG relative border-1 hover:border-mutedText border-transparent hover:shadow-lg transition-all ease-linear duration-300 quickView-parent overflow-hidden">
                                                <div className="quickView group" onClick={() => commonCarouselQuickViewHandle(item, index)}>
                                                    <p className="font-Poppins font-bold text-white text-base">Quick View</p>
                                                </div>
                                                <div className={`h-full w-full flex justify-center items-center transform scale-101 hover:scale-102 transition-all ease-linear duration-300 image-scale`}>
                                                    {/* <img src={item.image} alt="Section of different products" /> */}
                                                    <img src={item.thumbnail} alt="Section of different products" />
                                                </div>

                                                {
                                                    item.discount && <div className="w-9 h-9 bg-logobarElementBG absolute rounded-4.5 flex justify-center items-center top-4 right-4">
                                                        <p className="font-Poppins text-white text-tinyxl font-semibold">{item.discount}%</p>
                                                    </div>
                                                }
                                            </div>
                                            <div className="mt-6 h-40 flex flex-col">
                                                <p className="mb-2"><span className="font-DMSans text-xs text-commonCarouselMutedText mb-3 hover:text-topBarTextColor">{item?.product_category?.name}</span></p>
                                                <p className="font-Poppins font-semibold text-base text-topBarTextColor hover:text-logobarElementBG line-clamp-2">{item.name}</p>
                                                <div className="flex items-center mt-auto">
                                                    <div>
                                                        <Tippy content="4.00" placement="right">
                                                            <span className="mb-3 inline-block">
                                                                <i className="fas fa-star"></i>
                                                                <i className="fas fa-star"></i>
                                                                <i className="fas fa-star"></i>
                                                                <i className="fas fa-star"></i>
                                                                <i className="far fa-star"></i>
                                                            </span>
                                                        </Tippy>
                                                        <div className="flex items-center md:flex-col md:items-start lg:items-center  lg:flex-row">
                                                            <p className="font-Poppins text-base font-semibold text-logobarElementBG">TK. {item.selling_price}</p>
                                                            {item.oldPrice && <strike className="font-Poppins text-xs font-semibold text-mutedText ml-2 md:ml-0 lg:ml-2">TK. {item.oldPrice}</strike>}
                                                        </div>
                                                    </div>
                                                    <div className="ml-auto flex">
                                                        <button className="w-10 h-10 bg-timeBG rounded-2.5xl flex justify-center items-center mr-4 hover:bg-logobarElementBG group">
                                                            <i className="far fa-heart group-hover:text-white"></i>
                                                        </button>
                                                        <button className="w-10 h-10 bg-timeBG rounded-2.5xl flex justify-center items-center hover:bg-logobarElementBG group" onClick={() => handleAddingProductInCart(item)}>
                                                            <i className="far fa-shopping-bag group-hover:text-white"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </Slider>
                    {modal && <TestModal modal={modal} setModal={setModal} data={data} indexValue={indexValue} />}
                </div>
            }

            {
                carouselType === "topBrand" && <Slider {...settings}>
                    {
                        data.map((item, index) => (
                            <div key={index}>
                                <div className="flex items-center justify-center h-48 test">
                                    <img src={item} alt="Top Brands" className="w-1/2" />
                                </div>
                            </div>
                        ))
                    }
                </Slider>
            }

            {
                carouselType === "homeLifestyle" && <Slider {...settings} ref={commonCarouselRef}>
                    {
                        data.map((item, index) => (
                            <div key={index}>
                                {
                                    item.child
                                }
                            </div>
                        ))
                    }
                </Slider>
            }
        </>
    )
})

export default Carousel
