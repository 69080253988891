import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"

import MenMenu from './MenMenu'
import WomenMenu from './WomenMenu'
import BoysAndGirlsMenu from './BoysAndGirlsMenu'
import MotherMenu from './MotherMenu'
import PersonalMenu from './PersonalMenu'
import AllCategoryMenu from './AllCategoryMenu'

const Menubar = () => {

    // const active = "container mx-auto block absolute opacity-100 z-10 transition-all ease-linear duration-300"
    // const hidden = "container mx-auto hidden absolute opacity-0 z-0 transition-all ease-linear duration-300"
    const active = "container mx-auto pr-11 absolute visible opacity-100 translate-y-0 transition-all ease-linear duration-200"
    const hidden = "container mx-auto pr-11 absolute invisible opacity-0 transform translate-y-1 transition-all ease-linear duration-200"

    const [displayAllCategory, setDisplayAllCategory] = useState(hidden)
    const [displayMen, setDisplayMen] = useState(hidden)
    const [displayWomen, setDisplayWomen] = useState(hidden)
    const [displayBoysGirls, setDisplayBoysGirls] = useState(hidden)
    const [displayMother, setDisplayMother] = useState(hidden)
    const [displayPersonal, setDisplayPersonal] = useState(hidden)

    const [sticky, setSticky] = useState(false)
    const normalMenuStyle = "h-13 bg-logobarElementBG mt-4 hidden md:block sticky top-0 z-10 transition-all ease-linear duration-200";
    const stickyMenuStyle = "h-13 bg-topBarTextColor mt-4 hidden md:block sticky top-0 z-10 shadow-lg transition-all ease-linear duration-200";

    const normalMenuIndividualStyle = "h-full flex items-center hover:bg-menuHover cursor-pointer px-4"
    const stickyMenuIndividualStyle = "h-full flex items-center hover:bg-stickyHover cursor-pointer px-4"

    useEffect(() => {
        const toggleSticky = () => {
            if (window.scrollY > 146) {
                setSticky(true)
            } else {
                setSticky(false)
            }
        }
        document.addEventListener("scroll", toggleSticky)
        return () => document.removeEventListener("scroll", toggleSticky)
    }, [])


    return (
        <div className={sticky ? stickyMenuStyle : normalMenuStyle}>
            <div className="container mx-auto px-4 h-full">
                <div className="h-full flex justify-between font-Poppins font-semibold text-white items-center">

                    <div className={sticky ? stickyMenuIndividualStyle : normalMenuIndividualStyle} onMouseEnter={() => { setDisplayAllCategory(active) }} onMouseLeave={() => { setDisplayAllCategory(hidden) }}>
                        <p>ALL CATEGORIES</p>
                    </div>

                    <Link to="/main-category/abraham-grady" className={sticky ? stickyMenuIndividualStyle : normalMenuIndividualStyle} onMouseEnter={() => { setDisplayMen(active) }} onMouseLeave={() => { setDisplayMen(hidden) }}>
                        <p onClick={() => setDisplayMen(hidden)}>MEN</p>
                    </Link>

                    <Link to="/main-category/garth-gorczany" className={sticky ? stickyMenuIndividualStyle : normalMenuIndividualStyle} onMouseEnter={() => { setDisplayWomen(active) }} onMouseLeave={() => { setDisplayWomen(hidden) }}>
                        <p onClick={() => setDisplayWomen(hidden)}>WOMEN</p>
                    </Link>

                    <Link to="/main-category/idella-johns" className={sticky ? stickyMenuIndividualStyle : normalMenuIndividualStyle} onMouseEnter={() => { setDisplayBoysGirls(active) }} onMouseLeave={() => { setDisplayBoysGirls(hidden) }}>
                        <p onClick={() => setDisplayBoysGirls(hidden)}>BOYS & GIRLS</p>
                    </Link>

                    <Link to="/main-category/jewel-runte" className={sticky ? stickyMenuIndividualStyle : normalMenuIndividualStyle} onMouseEnter={() => { setDisplayMother(active) }} onMouseLeave={() => { setDisplayMother(hidden) }}>
                        <p onClick={() => setDisplayMother(hidden)}>MOTHER CARE</p>
                    </Link>

                    <Link to="/main-category/taryn-bergnaum" className={sticky ? stickyMenuIndividualStyle : normalMenuIndividualStyle} onMouseEnter={() => { setDisplayPersonal(active) }} onMouseLeave={() => { setDisplayPersonal(hidden) }}>
                        <p onClick={() => setDisplayPersonal(hidden)}>PERSONAL CARE</p>
                    </Link>
                </div>

                <div onMouseEnter={() => { setDisplayAllCategory(active) }} onMouseLeave={() => { setDisplayAllCategory(hidden) }}>
                    <div className={displayAllCategory}>
                        <AllCategoryMenu setDisplayAllCategory={setDisplayAllCategory} hidden={hidden} />
                    </div>
                </div>

                <div className={displayMen} onMouseEnter={() => { setDisplayMen(active) }} onMouseLeave={() => { setDisplayMen(hidden) }}>
                    {/* <div className={displayMen}> */}
                    <MenMenu setDisplayMen={setDisplayMen} hidden={hidden} />
                    {/* </div> */}
                </div>

                <div onMouseEnter={() => { setDisplayWomen(active) }} onMouseLeave={() => { setDisplayWomen(hidden) }}>
                    <div className={displayWomen}>
                        <WomenMenu />
                    </div>
                </div>

                <div onMouseEnter={() => { setDisplayBoysGirls(active) }} onMouseLeave={() => { setDisplayBoysGirls(hidden) }}>
                    <div className={displayBoysGirls}>
                        <BoysAndGirlsMenu />
                    </div>
                </div>

                <div onMouseEnter={() => { setDisplayMother(active) }} onMouseLeave={() => { setDisplayMother(hidden) }}>
                    <div className={displayMother}>
                        <MotherMenu />
                    </div>
                </div>

                <div className={displayPersonal} onMouseEnter={() => { setDisplayPersonal(active) }} onMouseLeave={() => { setDisplayPersonal(hidden) }}>
                    <PersonalMenu />
                </div>

            </div>
        </div>
    )
}

export default Menubar
