import React from 'react'
import Carousel from '../../ReuseableComponents/Carousel'


import Image1 from "../../../Assets/Images/MenFootwear/SportsShoe1.png"
import Image2 from "../../../Assets/Images/MenFootwear/SportsShoe2.png"
import Image3 from "../../../Assets/Images/MenFootwear/SportsShoe3.png"
import Image4 from "../../../Assets/Images/MenFootwear/SportsShoe4.png"

const data = [
    {
        category: "Sports Shoes",
        name: "Rerum necessitatibus saepe eveniet ut et voluptates",
        newPrice: 1750,
        image: Image1,
    },
    {
        category: "Sports Shoes",
        name: "Itaque earum rerum hic tenetur a sapiente delectus",
        newPrice: 2820,
        image: Image2,
    },
    {
        category: "Sports Shoes",
        name: "Itaque earum rerum hic tenetur a sapiente delectus",
        newPrice: 2820,
        image: Image3,
    },
]

const RecentlyViewedSlider = () => {
    return (
        <div className="carouselWithoutTab">
            <Carousel data={data} carouselType="commonCarousel" slidesToShow={3} arrows={true} mobileArrow={true} laptop={3} tab={2} mobile={1} dots={false} autoplay={false} centerMode={false} />
        </div>
    )
}

export default RecentlyViewedSlider
