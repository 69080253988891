import React from 'react'

import Image1 from "../../../Assets/Images/FilteredImage/FilteredImage1.png"
import Image2 from "../../../Assets/Images/FilteredImage/FilteredImage2.png"
import Image3 from "../../../Assets/Images/FilteredImage/FilteredImage3.png"

const WishlistSummary = () => {
    return (
        <div className="recent-orders">
            <div className="border-1 border-borderColor px-4 py-4 rounded-lg">
                <div className="hidden md:block">
                    <table className="w-full table-fixed">
                        <thead className="">
                            <tr className="border-b h-12">
                                <th className="font-Poppins font-medium text-sm w-3/4 text-left pl-2">Product</th>
                                <th className="font-Poppins font-medium text-sm w-1/4 text-left">Price</th>
                                <th className="font-Poppins font-medium text-sm w-2/4 text-left">Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr className="border-b h-32">
                                <td className="pl-2">
                                    <div className="flex items-center">
                                        <div className="h-28 w-1/4">
                                            <img src={Image1} className="h-full w-full object-contain" />
                                        </div>
                                        <div className="w-3/4 ml-4 mr-3">
                                            <p className="font-DMSans text-sm line-clamp-2">Quibusdam et aut officiis rety debitis aut rerum necessita fretynecessitatibus saepe eveniet ut et voluptates repudiandaemolestiae</p>
                                            <p className="font-DMSans text-sm mt-2"><b>Color</b>: Blue</p>
                                            <p className="font-DMSans text-sm mt-2"><b>Size</b>: L</p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <p className="font-DMSans text-sm text-logobarElementBG font-bold">Tk.1400</p>
                                    <strike className="font-DMSans text-sm1 text-mutedText">Tk.1600</strike>
                                </td>
                                <td>
                                    <div className="">
                                        <button className="font-Poppins text-sm px-4 py-2 bg-logobarElementBG text-white rounded-lg">Add to Bag</button>
                                        <button className="font-Poppins text-sm px-4 py-2 border-1 border-logobarElementBG hover:bg-logobarElementBG hover:text-white text-logobarElementBG rounded-lg ml-4">Remove</button>
                                    </div>
                                </td>
                            </tr>

                            <tr className="h-32">
                                <td className="pl-2">
                                    <div className="flex items-center">
                                        <div className="h-28 w-1/4">
                                            <img src={Image2} className="h-full w-full object-contain" />
                                        </div>
                                        <div className="w-3/4 ml-4 mr-3">
                                            <p className="font-DMSans text-sm line-clamp-2">Quibusdam et aut officiis rety debitis aut rerum necessita fretynecessitatibus saepe eveniet ut et voluptates repudiandaemolestiae</p>
                                            <p className="font-DMSans text-sm mt-2"><b>Color</b>: Blue</p>
                                            <p className="font-DMSans text-sm mt-2"><b>Size</b>: L</p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <p className="font-DMSans text-sm text-logobarElementBG font-bold">Tk.1400</p>
                                    <strike className="font-DMSans text-sm1 text-mutedText">Tk.1600</strike>
                                </td>
                                <td>
                                    <div className="">
                                        <button className="font-Poppins text-sm px-4 py-2 bg-logobarElementBG text-white rounded-lg">Add to Bag</button>
                                        <button className="font-Poppins text-sm px-4 py-2 border-1 border-logobarElementBG hover:bg-logobarElementBG hover:text-white text-logobarElementBG rounded-lg ml-4">Remove</button>
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                {/* For Responsiveness */}

                <div className="responsive-order-list md:hidden">
                    <div className="mb-4 flex flex-col items-center">
                        <div className="h-28 w-1/4">
                            <img src={Image1} className="h-full w-full object-contain" />
                        </div>
                        <div className="w-3/4 flex flex-col items-center">
                            <p className="font-DMSans text-sm line-clamp-2">Quibusdam et aut officiis debitis aut rerum necessita fretynecessitatibus saepe eveniet ut et voluptates repudiandaemolestiae</p>
                            <div className="mt-2 flex">
                                <p className="font-DMSans text-sm mr-8"><b>Color</b>: Blue</p>
                                <p className="font-DMSans text-sm"><b>Size</b>: L</p>
                            </div>
                            <div className="mt-3 flex items-center">
                                <p className="font-DMSans text-sm text-logobarElementBG font-bold mr-8">Tk.1400</p>
                                <strike className="font-DMSans text-sm1 text-mutedText">Tk.1600</strike>
                            </div>
                        </div>
                        <div className="mt-4">
                            <button className="font-Poppins text-sm px-4 py-2 bg-logobarElementBG text-white rounded-lg">Add to Bag</button>
                            <button className="font-Poppins text-sm px-4 py-2 border-1 border-logobarElementBG hover:bg-logobarElementBG hover:text-white text-logobarElementBG rounded-lg ml-4">Remove</button>
                        </div>
                    </div>
                    <hr className="mb-1" />
                    <hr className="mb-4" />
                    <div className="mb-4 flex flex-col items-center">
                        <div className="h-28 w-1/4">
                            <img src={Image2} className="h-full w-full object-contain" />
                        </div>
                        <div className="w-3/4 flex flex-col items-center">
                            <p className="font-DMSans text-sm line-clamp-2">Quibusdam et aut officiis debitis aut rerum necessita fretynecessitatibus saepe eveniet ut et voluptates repudiandaemolestiae</p>
                            <div className="mt-2 flex">
                                <p className="font-DMSans text-sm mr-8"><b>Color</b>: Black</p>
                                <p className="font-DMSans text-sm"><b>Size</b>: L</p>
                            </div>
                            <div className="mt-3 flex items-center">
                                <p className="font-DMSans text-sm text-logobarElementBG font-bold mr-8">Tk.1200</p>
                                <strike className="font-DMSans text-sm1 text-mutedText">Tk.1400</strike>
                            </div>
                        </div>
                        <div className="mt-4">
                            <button className="font-Poppins text-sm px-4 py-2 bg-logobarElementBG text-white rounded-lg">Add to Bag</button>
                            <button className="font-Poppins text-sm px-4 py-2 border-1 border-logobarElementBG hover:bg-logobarElementBG hover:text-white text-logobarElementBG rounded-lg ml-4">Remove</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default WishlistSummary
