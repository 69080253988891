import React, { useEffect, useState } from 'react'
import { useLocation, Link, useParams } from "react-router-dom"
import axios from 'axios'

import MainSlider from "../Components/PageComponents/MenFootwearPage/MainSlider"
import FootwearDealsOfTheDay from '../Components/PageComponents/MenFootwearPage/DealsOfTheDay/FootwearDealsOfTheDay'
import FootwearSOC from '../Components/PageComponents/MenFootwearPage/FootwearSOC'
import FootwearBestSeller from '../Components/PageComponents/MenFootwearPage/BestSeller/FootwearBestSeller'
import FootwearFirstBanner from '../Components/PageComponents/MenFootwearPage/Banners/FootwearFirstBanner'
import Sandals from '../Components/PageComponents/MenFootwearPage/Sandals'
import Sneakers from '../Components/PageComponents/MenFootwearPage/Sneakers/Sneakers'
import FormalShoes from '../Components/PageComponents/MenFootwearPage/FormalShoes/FormalShoes'
import SportsShoes from '../Components/PageComponents/MenFootwearPage/SportsShoes/SportsShoes'
import Boots from '../Components/PageComponents/MenFootwearPage/Boots/Boots'
import FootwearSecondBanner from '../Components/PageComponents/MenFootwearPage/Banners/FootwearSecondBanner'
import ThreeCarouseMain from '../Components/PageComponents/MenFootwearPage/ThreeCarouselSection/ThreeCarouseMain'
import TopBrands from '../Components/PageComponents/HomePage/TopBrands'

import BestSeller from '../Components/PageComponents/HomePage/BestSeller'

const MenFootwearPage = () => {

    const pathName = useLocation()
    const { main_category, sub_category } = useParams()

    const [subCategories, setSubCategories] = useState(null)

    useEffect(() => {
        axios.get(`/sub-categories/${sub_category}/slider`, {
            headers: {
                'Accept': 'application/json',
            }
        }).then(response => {
            // console.log(response.data)
            setSubCategories(response.data.product_categories)
        }).catch(errors => {
            console.log(errors.response)
        })
    }, [sub_category])

    useEffect(() => {
        // console.log(main_category)
        // console.log(sub_category)
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathName])



    return (
        <div>
            <MainSlider />
            <div className="container mx-auto px-4 mt-6">
                <ol className="list-reset flex text-sm font-medium font-Poppins">
                    <li><Link to="/" className="text-logobarElementBG">Home</Link></li>
                    <li><span className="mx-2 text-mutedText">/</span></li>
                    <li><Link to="/men" className="text-logobarElementBG">Men</Link></li>
                    <li><span className="mx-2 text-mutedText">/</span></li>
                    <li className="text-mutedText">Footwear</li>
                </ol>
            </div>
            <FootwearDealsOfTheDay />
            <FootwearSOC />
            <BestSeller />
            <FootwearFirstBanner />



            {
                subCategories && subCategories.map((item, index) => (
                    <div key={index}>
                        <Sandals item={item} />
                    </div>
                ))
            }


            {/* <Sneakers />
            <FormalShoes />
            <SportsShoes />
            <Boots /> */}



            <FootwearSecondBanner />
            <ThreeCarouseMain />
            <TopBrands />
        </div>
    )
}

export default MenFootwearPage
