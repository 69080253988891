import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router'

import MenFiltering from './Category&AttributeFiltering/MenFiltering'
import WomenFiltering from './Category&AttributeFiltering/WomenFiltering'
import BoysGirlsFiltering from './Category&AttributeFiltering/BoysGirlsFiltering'
import MotherCareFiltering from './Category&AttributeFiltering/MotherCareFiltering'
import PersonalCare from './Category&AttributeFiltering/PersonalCare'
import BrandFiltering from './Category&AttributeFiltering/BrandFiltering'
import MaterialFiltering from './Category&AttributeFiltering/MaterialFiltering'
import SizeFiltering from './Category&AttributeFiltering/SizeFiltering'
import ColorFiltering from './Category&AttributeFiltering/ColorFiltering'
import PriceFiltering from './Category&AttributeFiltering/PriceFiltering'
import RatingFiltering from './Category&AttributeFiltering/RatingFiltering'
import NewArrivalsFilteringSection from './Carousel/NewArrivalsFilteringSection'
import BannerCarousel from './Carousel/BannerCarousel'

const FilteringSidebar = () => {
    const { pathname } = useLocation()
    const [openMen, setOpenMen] = useState(false)
    const [openWomen, setOpenWomen] = useState(false)
    const [openBoysGirls, setOpenBoysGirls] = useState(false)
    const [openMotherCare, setOpenMotherCare] = useState(false)
    const [openPersonalCare, setOpenPersonalCare] = useState(false)

    // console.log(pathname)

    useEffect(() => {
        if (openMen) {
            setOpenWomen(false)
            setOpenBoysGirls(false)
            setOpenMotherCare(false)
            setOpenPersonalCare(false)
        }
    }, [openMen])

    useEffect(() => {
        if (openWomen) {
            setOpenMen(false)
            setOpenBoysGirls(false)
            setOpenMotherCare(false)
            setOpenPersonalCare(false)
        }
    }, [openWomen])

    useEffect(() => {
        if (openBoysGirls) {
            setOpenMen(false)
            setOpenWomen(false)
            setOpenMotherCare(false)
            setOpenPersonalCare(false)
        }
    }, [openBoysGirls])

    useEffect(() => {
        if (openMotherCare) {
            setOpenMen(false)
            setOpenWomen(false)
            setOpenBoysGirls(false)
            setOpenPersonalCare(false)
        }
    }, [openMotherCare])

    useEffect(() => {
        if (openPersonalCare) {
            setOpenMen(false)
            setOpenWomen(false)
            setOpenBoysGirls(false)
            setOpenMotherCare(false)
        }
    }, [openPersonalCare])



    return (
        <div>
            <p className="font-Poppins font-semibold text-xl text-sliderHeading mb-3.25">Categories</p>
            <div className="w-12.5 h-1.5 bg-logobarElementBG rounded-lg mb-5.5"></div>
            <MenFiltering openMen={openMen} setOpenMen={setOpenMen} pathname={pathname} />
            <WomenFiltering openWomen={openWomen} setOpenWomen={setOpenWomen} />
            <BoysGirlsFiltering openBoysGirls={openBoysGirls} setOpenBoysGirls={setOpenBoysGirls} />
            <MotherCareFiltering openMotherCare={openMotherCare} setOpenMotherCare={setOpenMotherCare} />
            <PersonalCare openPersonalCare={openPersonalCare} setOpenPersonalCare={setOpenPersonalCare} />
            <p className="font-Poppins font-semibold text-xl text-sliderHeading mb-3.25 mt-12.5">Shop By</p>
            <div className="w-12.5 h-1.5 bg-logobarElementBG rounded-lg mb-5.5"></div>
            <BrandFiltering />
            <MaterialFiltering />
            <SizeFiltering />
            <ColorFiltering />
            <PriceFiltering />
            <RatingFiltering />
            <NewArrivalsFilteringSection />
            <BannerCarousel />
        </div>
    )
}

export default FilteringSidebar
