import React, { useEffect, useState } from 'react'
import { useParams, Link } from "react-router-dom"
import axios from 'axios'

const OrderConfirmationPage = () => {

    const { slug } = useParams()

    const [orderData, setOrderData] = useState(null)

    useEffect(() => {
        if (slug) {
            axios.get(`/orders/${slug}`, {
                headers: {
                    Accept: 'application/json',
                }
            }).then(response => {
                console.log(response)
                setOrderData(response.data)
            }).catch(errors => {
                console.log(errors.response)
            })
        }
    }, [slug])

    const handlePaymentWithSSLCOMERZ = () => {
        axios.post("/payment-sslcommerz", {
            order_id: orderData.id
        }, {
            headers: {
                Accept: 'application/json',
            }
        }).then(response => {
            console.log(response)
            window.location.replace(response.data.GatewayPageURL)
        }).catch(errors => {
            console.log(errors.response)
        })
    }




    return (
        <div className="container mx-auto pl-4 pr-4 md:pr-7">

            <div className="mt-6">
                <ol className="list-reset flex text-sm font-medium font-Poppins">
                    <li><Link to="/" className="text-logobarElementBG">Home</Link></li>
                    <li><span className="mx-2 text-mutedText">/</span></li>
                    <li className="text-mutedText">Order Summary</li>
                </ol>
            </div>

            <div className="h-20 mt-4 flex justify-center">
                <div>
                    <p className="font-Poppins text-base font-medium">Order Number <span className="font-bold">{slug}</span></p>
                    {
                        orderData && orderData.set_paid == false ? (
                            <p className="font-Poppins text-base">Your order is on your way please pay with <span className="text-logobarElementBG font-medium">Cash on delivery</span></p>
                        ) : (
                            <p className="font-Poppins text-base"><span className="text-logobarElementBG font-medium">Thanks for the payment</span> your order is on your way.</p>
                        )
                    }
                </div>
            </div>

            {
                orderData && <div className="h-20 mt-4 mb-10 flex flex-col items-center justify-center">
                    {
                        orderData.status == "Pending" && orderData.set_paid == false ? (
                            <div>
                                <p className="text-center">Pay with</p>
                                <div className="mt-3 cursor-pointer" onClick={handlePaymentWithSSLCOMERZ}>
                                    <img src="https://securepay.sslcommerz.com/public/image/sslcommerz.png" />
                                </div>
                            </div>
                        ) : <p>Money is successfully paid</p>
                    }
                </div>
            }

            <div className="grid grid-cols-2 gap-4 mt-4">

                <div className="">
                    <div className="flex flex-col items-center">
                        <p className="mb-4">Delivery Address</p>
                        {orderData && <p>{`${orderData.shipping.address}, ${orderData.shipping.area}, ${orderData.shipping.city}, ${orderData.shipping.region}`}</p>}
                        {orderData && <p>Mobile Number: {orderData.shipping.phone}</p>}
                    </div>
                </div>

                <div className="">
                    <div className="flex flex-col">
                        <p className="text-center mb-4">Order Summary</p>

                        <div className="grid grid-cols-12 mb-2">

                            <div className="col-span-8">
                                <p>Sub-Total</p>
                            </div>

                            <div className="col-span-4">
                                {orderData && <p>{orderData.sub_total}</p>}
                            </div>

                        </div>

                        <div className="grid grid-cols-12 mb-2">

                            <div className="col-span-8">
                                <p>Shipping Cost</p>
                            </div>

                            <div className="col-span-4">
                                {orderData && <p>{orderData.shipping_total}</p>}
                            </div>

                        </div>


                        <div className="grid grid-cols-12 mb-2">

                            <div className="col-span-8">
                                <p>Total Amount To Be Paid</p>
                            </div>

                            <div className="col-span-4">
                                {orderData && <p>{orderData.total}</p>}
                            </div>

                        </div>

                    </div>
                </div>

            </div>


        </div>
    )
}

export default OrderConfirmationPage
