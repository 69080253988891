import React from 'react'

import MenSecondB from "../../../Assets/Images/MenCategory/MenSecondB.png"

const MenSecondBanner = () => {
    return (
        <div className="container mx-auto px-4 mt-8">
            <div className="overflow-hidden border-1 hover:border-mutedText border-transparent hover:shadow-md transition-all ease-linear duration-300 md:mr-7">
                <img src={MenSecondB} alt="Banner Section"
                    className="w-full transform scale-101 hover:scale-102 cursor-pointer transition-all ease-linear duration-300" />
            </div>
        </div>
    )
}

export default MenSecondBanner
