import React from 'react'

const data = [
    {
        size: 37
    },
    {
        size: 38
    },
    {
        size: 39
    },
    {
        size: 40
    },
    {
        size: 41
    },
    {
        size: 42
    },
    {
        size: 43,
    },
    {
        size: 44,
    },
    {
        size: 45,
    },
    {
        size: 46,
    },
]

const SizeFiltering = () => {
    return (
        <div className="sizeFiltering mt-11">
            <p className="font-Poppins font-semibold text-base text-topBarTextColor mb-2.75">Size Options</p>
            <hr className="border-t-2 mb-4.5" />
            <div className="flex flex-wrap">
                {
                    data.map((item, index) => (
                        <div className={`w-7.5 h-7.5 ${index === 3 ? "bg-logobarElementBG" : "bg-topBarBG"} flex justify-center items-center mr-3.25 mb-3.25`} style={{ borderRadius: "50%" }} key={index}>
                            <p className={`font-DMSans text-sm ${index == 3 ? "text-white" : "text-sliderDescription"}`}>{item.size}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default SizeFiltering
