import React, { useState, useRef, useEffect } from 'react'
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios'

import CompanyLogo from "../../../Assets/Images/CompanyLogo.png"
import CompanyName from "../../../Assets/Images/CompanyName.png"
// import Wishlisht from "../../../Assets/Images/wishlist.png"

import { BiShoppingBag } from "react-icons/bi"
import { AiOutlineHeart } from "react-icons/ai"

import Sidebar from '../../../PageLayout/Sidebar/Sidebar'

import SignUpSignInModal from '../../../PageLayout/SignUpSignInModal'
import { savingTokenValue, savingMobileNumber } from "../../../ReduxStore/LogInFolder/UserInfo"


import { IoMdMenu } from "react-icons/io"

const tokenFromLocalStorage = JSON.parse(localStorage.getItem("FFtoken") || "null")

const LogoBar = () => {

    const [wishlist, setWishlist] = useState(0)
    // const [cart, setCart] = useState(10)
    const { cartItem } = useSelector(state => state.testCart)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(savingTokenValue(tokenFromLocalStorage))
    }, [])

    const { token, mobileNumber } = useSelector(state => state.authInfo)

    useEffect(() => {
        if (token != null) {
            // console.log(token)
            axios.get("/customer/profile",
                {
                    headers: {
                        Authorization: "Bearer " + token,
                        Accept: 'application/json',
                    }
                }).then(response => {
                    // console.log(response)
                    dispatch(savingMobileNumber(response.data.phone))
                }).catch(errors => {
                    console.log(errors.response)
                })
        }
    }, [token])

    useEffect(() => {
        // console.log(token)
        // console.log(mobileNumber)
    }, [token, mobileNumber])

    const [isOpen, setIsOpen] = useState(false);

    const [signInModal, setSignInModal] = useState(false)

    const closeSidebarWhenClickedOutside = (ref, hamburgerRef) => {
        // console.log(ref.current)

        // console.log(ref.current)
        function handleClickOutside(event) {
            // console.log(ref.current.contains(event.target))
            if (ref.current && !ref.current.contains(event.target) && !hamburgerRef.current.contains(event.target)) {
                setIsOpen(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }

    const sidebarRef = useRef(null)
    const hamburgerRef = useRef(null)

    closeSidebarWhenClickedOutside(sidebarRef, hamburgerRef)

    return (
        <div className="container mx-auto px-4 mt-4">
            <div className="flex items-center w-full">
                <div className="flex items-center">
                    <Link to="/" className="w-20 h-12 xl:w-25 xl:h-16 mr-4">
                        <img src={CompanyLogo} alt="Company Logo" />
                    </Link>
                    <Link to="/" className="w-56 h-8 mr-20 hidden sm:block">
                        <img src={CompanyName} alt="Company Name" />
                    </Link>
                </div>
                <div className="hidden lg:flex">
                    <input type="text" placeholder="What do you want to buy?" className="w-60 h-12 xl:w-106 xl:h-12 px-7 border-2 focus:outline-none rounded-l border-r-0" />
                    <button className="h-12 w-30 bg-logobarElementBG hover:bg-menuHover font-Poppins font-semibold text-white flex justify-center items-center rounded-r">
                        Search
                    </button>
                </div>
                <div className="account-wishlist flex items-center ml-auto relative">

                    <div className="mr-4 xs:mr-4">
                        {
                            token ? (
                                <Link className="font-DMSans text-sm font-bold text-topBarTextColor hover:text-logobarElementBG cursor-pointer" to="/customer/account-info">
                                    {/* {mobileNumber} */}
                                    <div className="flex items-center">
                                        <div className="text-2xl flex items-center">
                                            <i className="far fa-user-circle"></i>
                                        </div>
                                        <p className="font-Poppins text-base font-semibold ml-2">My Account</p>
                                    </div>
                                </Link>
                                // <div className="flex items-center text-topBarTextColor hover:text-logobarElementBG cursor-pointer pr-6" onClick={() => setSignInModal(prevState => !prevState)}>
                                //     <div className="text-2xl flex items-center">
                                //         <i className="far fa-user-circle"></i>
                                //     </div>
                                //     <p className="font-DMSans text-sm font-bold ml-2 uppercase">Register <br />Login</p>
                                // </div>
                            ) : (
                                <div className="flex items-center text-topBarTextColor hover:text-logobarElementBG cursor-pointer pr-6" onClick={() => setSignInModal(prevState => !prevState)}>
                                    <div className="text-2xl flex items-center">
                                        <i className="far fa-user-circle"></i>
                                    </div>
                                    <p className="font-DMSans text-sm font-bold ml-2 uppercase">Register <br />Login</p>
                                </div>
                            )
                        }
                    </div>

                    <div className="relative mr-6 cursor-pointer">
                        {/* <img src={Wishlisht} alt="Wishlist" /> */}
                        <AiOutlineHeart fontSize={30} className="text-topBarTextColor hover:text-logobarElementBG" />
                        <div className="absolute w-4 h-4 bg-logobarElementBG rounded-lg flex justify-center items-center" style={{ top: -4, right: -8 }}>
                            <p className="text-white font-Poppins font-bold text-xs">{wishlist}</p>
                        </div>
                    </div>

                    {/* <div className="w-5 h-6 relative cursor-pointer">
                        <BiShoppingBag fontSize={30} className="text-topBarTextColor hover:text-logobarElementBG" />
                        <div className="absolute w-4 h-4 bg-logobarElementBG rounded-lg flex justify-center items-center" style={{ top: -6, right: -10 }}>
                            <p className="text-white font-Poppins font-bold text-xs">{cartItem}</p>
                        </div>
                    </div> */}

                    {/* Responsive Menubar Starts */}

                    <button
                        className="flex flex-col h-10 w-10 justify-center items-center group md:hidden"
                        onClick={() => setIsOpen((prevState) => !prevState)}
                        ref={hamburgerRef}
                    >
                        <IoMdMenu size={35} color="#222222" />
                    </button>

                </div>
            </div>

            {/* Sidebar of Mobile Navigation */}

            <Sidebar isOpen={isOpen} sidebarRef={sidebarRef} setIsOpen={setIsOpen} />

            <div className="flex mt-4 lg:hidden mb-4">
                <input type="text" placeholder="Search Product" className="w-4/5 h-12 px-7 border-2 focus:outline-none rounded-l border-r-0" />
                <button className="h-12 w-1/5 bg-logobarElementBG font-Poppins font-semibold text-white flex justify-center items-center rounded-r cursor-pointer">
                    <i className="fas fa-search"></i>
                </button>
            </div>
            <SignUpSignInModal signInModal={signInModal} setSignInModal={setSignInModal} />
        </div>
    )
}

export default LogoBar
