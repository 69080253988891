import React from 'react'

import MFB1 from "../../../Assets/Images/MenCategory/MFB1.png"
import MFB2 from "../../../Assets/Images/MenCategory/MFB2.png"

const MenFirstBanner = () => {
    return (
        <div className="container mx-auto px-4 mt-8">
            <div className="md:pr-7">
                <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
                    <div className="overflow-hidden border-1 hover:border-mutedText border-transparent hover:shadow-md transition-all ease-linear duration-300">
                        <img src={MFB1} alt="Banner Section" className="w-full transform scale-101 hover:scale-102 cursor-pointer transition-all ease-linear duration-300" />
                    </div>
                    <div className="overflow-hidden border-1 hover:border-mutedText border-transparent hover:shadow-md transition-all ease-linear duration-300">
                        <img src={MFB2} alt="Banner Section" className="w-full transform scale-101 hover:scale-102 cursor-pointer transition-all ease-linear duration-300" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MenFirstBanner
