import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom"
import axios from 'axios'

import MainSlider from '../Components/PageComponents/HomePage/MainSlider'
import DealsOfTheDay from '../Components/PageComponents/HomePage/DealsOfTheDay'
import BestSeller from '../Components/PageComponents/HomePage/BestSeller'
import MenFashion from '../Components/PageComponents/HomePage/MenFashion'
import WomenFashion from '../Components/PageComponents/HomePage/WomenFashion'
import BoyClothing from '../Components/PageComponents/HomePage/BoyClothing'
import FirstBanner from '../Components/PageComponents/HomePage/FirstBanner'
import SecondBanner from '../Components/PageComponents/HomePage/SecondBanner'
import GirlsClothing from '../Components/PageComponents/HomePage/GirlsClothing'
import ThirdBanner from '../Components/PageComponents/HomePage/ThirdBanner'
import HomeLivingGridTab from '../Components/PageComponents/HomePage/Home&Lifestyle/HomeLivingGridTab'
import TopBrands from '../Components/PageComponents/HomePage/TopBrands'


const HomePage = () => {

    const { pathname } = useLocation()
    // console.log(pathname)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])


    const [categories, setCategories] = useState(null)

    useEffect(() => {
        axios.get("/homepage/slider", {
            headers: {
                'Accept': 'application/json',
            }
        }).then(response => {
            // console.log(response.data)
            setCategories(response.data)
        }).catch(errors => {
            console.log(errors.response)
        })
    }, [])

    return (
        <div>
            <MainSlider />
            <DealsOfTheDay />
            <BestSeller />
            <FirstBanner />

            {
                categories && categories.filter((item, index) => index < 2).map((item, index) => (
                    <div key={index}>
                        <MenFashion item={item} />
                    </div>
                ))
            }







            {/* <WomenFashion /> */}



            <SecondBanner />


            {
                categories && categories.filter((item, index) => index > 1).map((item, index) => (
                    <div key={index}>
                        <MenFashion item={item} />
                    </div>
                ))
            }


            {/* <BoyClothing />
            <GirlsClothing /> */}



            <ThirdBanner />
            <HomeLivingGridTab />
            <TopBrands />
        </div>
    )
}

export default HomePage
