import React, { useState, useEffect } from 'react'
import Select from 'react-select'

const optionsForMonth = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
]

const optionsForStatus = [
    { value: 1, label: "Pending" },
    { value: 2, label: "Processing" },
    { value: 3, label: "Approve" },
    { value: 4, label: "Hold" },
    { value: 5, label: "On Shipping" },
    { value: 6, label: "Shipped" },
    { value: 7, label: "Return" },
    { value: 8, label: "Cancelled" },
    { value: 9, label: "Completed" },
]

const styles = {
    option: (provided, state) => ({
        ...provided,
        fontSize: 16,
        fontFamily: "Poppins",
        color: state.isSelected ? "white" : "#252525",
        backgroundColor: state.isSelected ? "#e5371b" : "",
        '&:hover': { backgroundColor: state.isSelected ? "" : '#f6f6f6' },
    }),
    singleValue: (provided, state) => ({
        ...provided,
        fontSize: 16,
        fontFamily: "Poppins",
        color: "#252525",
    }),
    placeholder: (provided, state) => ({
        ...provided,
        fontSize: 14,
        fontFamily: "Poppins",
        color: "#252525",
    }),
    control: (provided, state) => ({
        ...provided,
        boxShadow: "none",
        backgroundColor: "#f6f6f6",
        width: 150,
        border: '1px solid #e5371b', // default border color
        '&:hover': { borderColor: '#e5371b' }, // border style on hover
        // You can also use state.isFocused to conditionally style based on the focus state
    })
}

const OrderSummary = () => {

    const [month, setMonth] = useState(null)
    const [status, setStatus] = useState(null)


    return (
        <div className="recent-orders">
            <div className="bg-topBarBG rounded-lg mb-4 flex flex-col md:flex-row md:items-center px-4 py-2 justify-between">

                <div className="flex flex-col md:flex-row">
                    <div className="flex items-center mb-3 md:mb-0">
                        <p className="font-Poppins font-semibold text-logobarElementBG text-base mr-2">Month</p>
                        <div style={{ width: 150 }}>
                            <Select
                                value={month}
                                onChange={option => setMonth(option)}
                                options={optionsForMonth}
                                placeholder="Select Month"
                                isSearchable={false}
                                isClearable={false}
                                className="focus:outline-none"
                                styles={styles}
                            />
                        </div>
                    </div>

                    <div className="mb-3 md:mb-0 flex items-center md:ml-4">
                        <p className="font-Poppins font-semibold text-logobarElementBG text-base mr-2">Status</p>
                        <div style={{ width: 150 }}>
                            <Select
                                value={status}
                                onChange={option => setStatus(option)}
                                options={optionsForStatus}
                                placeholder="Select status"
                                isSearchable={false}
                                isClearable={false}
                                className="focus:outline-none"
                                styles={styles}
                            />
                        </div>
                    </div>
                </div>

                <div>
                    <input className="border-1 h-9 w-60 focus:outline-none font-DMSans font-medium pl-4" placeholder="Search By Order Id" />
                </div>

            </div>
            <div className="border-1 border-borderColor px-4 py-4 rounded-lg">
                <div className="hidden md:block">
                    <table className="w-full table-fixed">
                        <thead className="">
                            <tr className="border-b h-12">
                                <th className="font-Poppins font-medium text-sm w-1/6 text-left pl-2">Order Id</th>
                                <th className="font-Poppins font-medium text-sm w-1/6 text-left">Date</th>
                                <th className="font-Poppins font-medium text-sm w-1/6 text-left">Ship To</th>
                                <th className="font-Poppins font-medium text-sm w-1/6 text-left">Amount</th>
                                <th className="font-Poppins font-medium text-sm w-1/6 text-left">Payment Method</th>
                                <th className="font-Poppins font-medium text-sm w-1/6 text-left">Status</th>
                                <th className="font-Poppins font-medium text-sm w-1/6 text-left">Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr className="border-b h-12">
                                <td className="pl-2">
                                    <p className="font-DMSans text-sm1">171081849</p>
                                </td>
                                <td>
                                    <p className="font-DMSans text-sm1">10/17/21</p>
                                </td>
                                <td>
                                    <p className="font-DMSans text-sm1">Argha Shipan Sarker</p>
                                </td>
                                <td>
                                    <p className="font-DMSans text-sm1 text-logobarElementBG">Tk.2059</p>
                                </td>
                                <td>
                                    <p className="font-DMSans text-sm1">BKash</p>
                                </td>
                                <td>
                                    <p className="font-DMSans text-sm1">Delivered</p>
                                </td>
                                <td>
                                    <p className="font-DMSans text-sm1 hover:text-logobarElementBG font-medium">View</p>
                                </td>
                            </tr>

                            <tr className="h-12">
                                <td className="pl-2">
                                    <p className="font-DMSans text-sm1">171099999</p>
                                </td>
                                <td>
                                    <p className="font-DMSans text-sm1">12/06/18</p>
                                </td>
                                <td>
                                    <p className="font-DMSans text-sm1">Argha Shipan Sarker</p>
                                </td>
                                <td>
                                    <p className="font-DMSans text-sm1 text-logobarElementBG">Tk.1059</p>
                                </td>
                                <td>
                                    <p className="font-DMSans text-sm1">Nagad</p>
                                </td>
                                <td>
                                    <p className="font-DMSans text-sm1">Complete</p>
                                </td>
                                <td>
                                    <p className="font-DMSans text-sm1 hover:text-logobarElementBG font-medium">View</p>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                {/* For Responsiveness */}

                <div className="responsive-order-list md:hidden">
                    <div className="mb-4">
                        <p className="font-Poppins font-medium text-sm mb-1">Order Id: <span className="font-DMSans text-sm1 font-normal inline-block ml-2">171081849</span></p>
                        <p className="font-Poppins font-medium text-sm mb-1">Date: <span className="font-DMSans text-sm1 font-normal inline-block ml-2">10/17/21</span></p>
                        <p className="font-Poppins font-medium text-sm mb-1">Ship To:  <span className="font-DMSans text-sm1 font-normal inline-block ml-2">Argha Shipan Sarker</span></p>
                        <p className="font-Poppins font-medium text-sm mb-1">Order Total:  <span className="font-DMSans text-sm1 font-normal inline-block ml-2">Tk.2059</span></p>
                        <p className="font-Poppins font-medium text-sm mb-1">Status:  <span className="font-DMSans text-sm1 font-normal inline-block ml-2">Delivered</span></p>
                        <p className="font-Poppins font-medium text-sm font-medium">View</p>
                    </div>
                    <hr className="mb-1" />
                    <hr className="mb-4" />
                    <div className="">
                        <p className="font-Poppins font-medium text-sm mb-1">Order Id: <span className="font-DMSans text-sm1 font-normal inline-block ml-2">171099999</span></p>
                        <p className="font-Poppins font-medium text-sm mb-1">Date: <span className="font-DMSans text-sm1 font-normal inline-block ml-2">12/06/18</span></p>
                        <p className="font-Poppins font-medium text-sm mb-1">Ship To:  <span className="font-DMSans text-sm1 font-normal inline-block ml-2">Argha Shipan Sarker</span></p>
                        <p className="font-Poppins font-medium text-sm mb-1">Order Total:  <span className="font-DMSans text-sm1 font-normal inline-block ml-2">Tk.1059</span></p>
                        <p className="font-Poppins font-medium text-sm mb-1">Status:  <span className="font-DMSans text-sm1 font-normal inline-block ml-2">Complete</span></p>
                        <p className="font-Poppins font-medium text-sm font-medium">View</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default OrderSummary
