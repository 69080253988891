import React from 'react'

import SecondBanner1 from "../../../Assets/Images/SecondBanner1.png"
import SecondBanner2 from "../../../Assets/Images/SecondBanner2.png"
import SecondBanner3 from "../../../Assets/Images/SecondBanner3.png"
import SecondBanner4 from "../../../Assets/Images/SecondBanner4.png"
import SecondBanner5 from "../../../Assets/Images/SecondBanner5.png"

const SecondBanner = () => {
    return (
        <div className="container mx-auto px-4 mt-8">
            <div className="md:pr-7 grid grid-cols-1 gap-y-5 gap-x-6 md:flex">
                <div className="flex flex-col">
                    <div className="overflow-hidden border-1 hover:border-mutedText border-transparent hover:shadow-md transition-all ease-linear duration-300">
                        <img src={SecondBanner1} alt="first banner" className="w-full md:w-70 h-54 transform scale-101 hover:scale-102 cursor-pointer transition-all ease-linear duration-300" />
                    </div>
                    <div className="overflow-hidden mt-6 border-1 hover:border-mutedText border-transparent hover:shadow-md transition-all ease-linear duration-300">
                        <img src={SecondBanner2} alt="first banner" className="w-full md:w-70 h-54 transform scale-101 hover:scale-102 cursor-pointer transition-all ease-linear duration-300" />
                    </div>
                </div>
                <div className="overflow-hidden border-1 hover:border-mutedText border-transparent hover:shadow-md transition-all ease-linear duration-300">
                    <img src={SecondBanner3} alt="first banner" className="w-full h-full transform scale-101 hover:scale-102 cursor-pointer transition-all ease-linear duration-300" />
                </div>
                <div className="flex flex-col">
                    <div className="overflow-hidden border-1 hover:border-mutedText border-transparent hover:shadow-md transition-all ease-linear duration-300">
                        <img src={SecondBanner4} alt="first banner" className="w-full md:w-70 h-54 transform scale-101 hover:scale-102 cursor-pointer transition-all ease-linear duration-300" />
                    </div>
                    <div className="overflow-hidden mt-6 border-1 hover:border-mutedText border-transparent hover:shadow-md transition-all ease-linear duration-300">
                        <img src={SecondBanner5} alt="first banner" className="w-full md:w-70 h-54 transform scale-101 hover:scale-102 cursor-pointer transition-all ease-linear duration-300" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SecondBanner
