import { configureStore } from "@reduxjs/toolkit"

import testingCartReducer from "./CartSystem/CartStore"
import userInfoReducer from "./LogInFolder/UserInfo"
import checkOutReducer from "./CheckOutSystem/CheckOutStore"


export default configureStore({
    reducer: {
        testCart: testingCartReducer,
        authInfo: userInfoReducer,
        checkOutData: checkOutReducer,
    }
})