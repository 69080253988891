import React from 'react'
import FirstBannerImage from "../../../Assets/Images/FirstBanner.jpg"
import "./firstBanner.css"
import "./thirdBanner.css"

const FirstBanner = () => {
    return (
        //hover design 1
        // <div className="container mx-auto px-4 mt-8">
        //     <div className="h-50 w-291 md:mr-7 border-new">
        //         <div className="border-new-child">
        //             <img src={FirstBannerImage} alt="Sale Banner"
        //                 // className="transform scale-101 hover:scale-105 cursor-pointer transition-all ease-linear duration-300" 
        //                 className=""
        //             />
        //         </div>
        //     </div>
        // </div>

        //hover design 2
        // <div className="container mx-auto px-4 mt-8">
        //     <div className="h-50 w-291 md:mr-7 banner-border relative">
        //         {/* <div className="border-new-child"> */}
        //         <img src={FirstBannerImage} alt="Sale Banner"
        //             // className="transform scale-101 hover:scale-105 cursor-pointer transition-all ease-linear duration-300" 
        //             className=""
        //         />
        //         {/* </div> */}
        //     </div>
        // </div>

        //hover design 3
        <div className="container mx-auto px-4 mt-8">
            <div className="md:mr-7 overflow-hidden border-1 hover:border-mutedText border-transparent hover:shadow-md transition-all ease-linear duration-300">
                {/* <div className="first-banner-child"> */}
                <img src={FirstBannerImage} alt="Sale Banner"
                    className="transform scale-101 hover:scale-102 cursor-pointer transition-all ease-linear duration-300"
                />
                {/* </div> */}
            </div>
        </div>
        // <div className="container mx-auto px-4 mt-8">
        //     <div className="md:mr-7 first-banner">
        //         <div className="first-banner-child overflow-hidden">
        //             <img src={FirstBannerImage} alt="Sale Banner"
        //                 className="transform scale-101 hover:scale-102 cursor-pointer transition-all ease-linear duration-300"
        //             />
        //         </div>
        //     </div>
        // </div>
    )
}

export default FirstBanner
