import React from 'react'
import "./thirdBanner.css"

import ThirdBanner1 from "../../../Assets/Images/ThirdBanner1.png"
import ThirdBanner2 from "../../../Assets/Images/ThirdBanner2.png"
import ThirdBanner3 from "../../../Assets/Images/ThirdBanner3.png"

const ThirdBanner = () => {
    return (
        <div className="container mx-auto px-4 mt-8">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:pr-7">
                <div className="overflow-hidden border-1 hover:border-mutedText border-transparent hover:shadow-md transition-all ease-linear duration-300">
                    {/* <div className="first-banner-child"> */}
                    <img src={ThirdBanner1} alt="Third Banner" className="w-full transform scale-101 hover:scale-102 cursor-pointer transition-all ease-linear duration-300" />
                    {/* </div> */}
                </div>
                <div className="overflow-hidden border-1 hover:border-mutedText border-transparent hover:shadow-md transition-all ease-linear duration-300">
                    <img src={ThirdBanner2} alt="Third Banner" className="w-full transform scale-101 hover:scale-102 cursor-pointer transition-all ease-linear duration-300" />
                </div>
                <div className="overflow-hidden border-1 hover:border-mutedText border-transparent hover:shadow-md transition-all ease-linear duration-300">
                    <img src={ThirdBanner3} alt="Third Banner" className="w-full transform scale-101 hover:scale-102 cursor-pointer transition-all ease-linear duration-300" />
                </div>
            </div>
        </div>
    )
}

export default ThirdBanner
