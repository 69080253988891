import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux"
import './App.css';
import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom"

import TopBar from './Components/PageComponents/HomePage/TopBar';
import LogoBar from './Components/PageComponents/HomePage/LogoBar';
import Menubar from './PageLayout/Menubar/Menubar';
import HomePage from './Pages/HomePage';
import Footer from './PageLayout/Footer';
import CopyRight from './PageLayout/CopyRight';
import BackToTop from './PageLayout/BackToTop';
import TopBrands from './Components/PageComponents/HomePage/TopBrands';
import NewsLetter from './Components/PageComponents/HomePage/NewsLetter';

import MenCategoryPage from './Pages/MenCategoryPage';
import MenFootwearPage from './Pages/MenFootwearPage';
import RunningShoePage from './Pages/RunningShoePage';
import IndividualProduct from './Pages/IndividualProduct';

import CartButton from './PageLayout/CartButton';
import CartSideBar from './PageLayout/CartSideBar';

import UserAccount from './Pages/UserAccount';

import CheckoutPage from './PageLayout/CheckoutPage';

import OrderConfirmationPage from './Pages/OrderConfirmationPage';

import axios from 'axios';

import { addingCartValueFromLocalStorage, calculatingTotalPrice, calculatingTotalCartProducts } from "./ReduxStore/CartSystem/CartStore"

const cartValueFromLocalStorage = JSON.parse(localStorage.getItem("cart") || "[]")


// axios.defaults.baseURL = "https://api.fashionfield.viserx.net/api"
axios.defaults.baseURL = "https://local.fashionfield.viserx.net/api"

function App() {
  // const { pathname } = useLocation()
  // console.log(pathname)
  // console.log(window.location.href)
  const [cartSidebar, setCartSideBar] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(addingCartValueFromLocalStorage(cartValueFromLocalStorage))
    dispatch(calculatingTotalPrice())
    dispatch(calculatingTotalCartProducts())
  }, [])



  return (
    <Router>
      <TopBar />
      <LogoBar />
      <Menubar />
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/main-category/:slug" exact component={MenCategoryPage} />
        <Route path="/sub-category/:main_category/:sub_category" exact component={MenFootwearPage} />
        <Route path="/category/:id" exact component={RunningShoePage} />
        <Route path="/product" exact component={IndividualProduct} />
        <Route path="/customer/:slug" exact component={UserAccount} />
        <Route path="/checkout" exact component={CheckoutPage} />
        <Route path="/order/:slug" exact component={OrderConfirmationPage} />
      </Switch>
      {/* <TopBrands /> */}
      <NewsLetter />
      <Footer />
      <CopyRight />
      {/* <BackToTop /> */}
      <CartButton setCartSideBar={setCartSideBar} />
      <CartSideBar cartSidebar={cartSidebar} setCartSideBar={setCartSideBar} />
    </Router>
  );
}

export default App;

// testing git
