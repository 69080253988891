import React from 'react'
import Tippy from "@tippyjs/react"
import 'tippy.js/dist/tippy.css';

import AddressSummary from './AddressSummary';
import AccountBasicInfo from './AccountBasicInfo';


const AccountSummary = () => {
    return (
        <div>

            <div className="account-info mb-8">
                <p className="font-Poppins text-lg">Account Information</p>
                <div className="border-1 border-borderColor px-4 py-4 rounded-lg mt-4">
                    <p className="font-DMSans text-sm font-medium mb-3">Contact Information</p>
                    <AccountBasicInfo />
                    <div className="flex">
                        <p className="font-DMSans text-sm1 font-medium mr-2 cursor-pointer hover:text-logobarElementBG">Edit</p>
                        <div className="border-1 border-borderColor mr-2"></div>
                        <p className="font-DMSans text-sm1 font-medium cursor-pointer hover:text-logobarElementBG">Change Password</p>
                    </div>
                </div>
            </div>

            <div className="address-book mb-8">
                <div className="flex justify-between items-center mb-4">
                    <p className="font-Poppins text-lg">Address Book</p>
                    <button className="font-Poppins text-xs px-4 py-2 border-1 border-logobarElementBG text-logobarElementBG hover:bg-logobarElementBG hover:text-white rounded-lg">MANAGE ADDRESSES</button>
                </div>
                <AddressSummary summaryPage={true} />
            </div>

            <div className="review-list mb-8">
                <div className="flex justify-between items-center">
                    <p className="font-Poppins text-lg">My Recent Reviews</p>
                    <button className="font-Poppins text-xs px-4 py-2 border-1 border-logobarElementBG text-logobarElementBG hover:bg-logobarElementBG hover:text-white rounded-lg">VIEW ALL</button>
                </div>
                <div className="border-1 border-borderColor px-4 py-4 rounded-lg mt-4">
                    <div className="mb-4">
                        <p className="font-DMSans text-sm1 mb-1">Xiaomi Basic In-Ear Headphone</p>
                        <div className="flex items-center">
                            <p className="font-DMSans text-sm1 font-semibold mr-2">Rating:</p>
                            <Tippy content="4.00" placement="right">
                                <span className="inline-block">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="far fa-star"></i>
                                </span>
                            </Tippy>
                        </div>
                    </div>
                    <div className="">
                        <p className="font-DMSans text-sm1 mb-1">Haylou LS02 Smart Watch Global Version</p>
                        <div className="flex items-center">
                            <p className="font-DMSans text-sm1 font-semibold mr-2">Rating:</p>
                            <Tippy content="4.00" placement="right">
                                <span className="inline-block">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="far fa-star"></i>
                                </span>
                            </Tippy>
                        </div>
                    </div>
                </div>
            </div>

            <div className="recent-orders">
                <div className="flex justify-between items-center">
                    <p className="font-Poppins text-lg">Recent Orders</p>
                    <button className="font-Poppins text-xs px-4 py-2 border-1 border-logobarElementBG text-logobarElementBG hover:bg-logobarElementBG hover:text-white rounded-lg">VIEW ALL</button>
                </div>
                <div className="border-1 border-borderColor px-4 py-4 rounded-lg mt-4">
                    <div className="hidden md:block">
                        <table className="w-full table-fixed">
                            <thead className="">
                                <tr className="border-b h-12">
                                    <th className="font-Poppins font-medium text-sm w-1/6 text-left pl-2">Order Id</th>
                                    <th className="font-Poppins font-medium text-sm w-1/6 text-left">Date</th>
                                    <th className="font-Poppins font-medium text-sm w-1/6 text-left">Ship To</th>
                                    <th className="font-Poppins font-medium text-sm w-1/6 text-left">Order Total</th>
                                    <th className="font-Poppins font-medium text-sm w-1/6 text-left">Status</th>
                                    <th className="font-Poppins font-medium text-sm w-1/6 text-left">Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr className="border-b h-12">
                                    <td className="pl-2">
                                        <p className="font-DMSans text-sm1">171081849</p>
                                    </td>
                                    <td>
                                        <p className="font-DMSans text-sm1">10/17/21</p>
                                    </td>
                                    <td>
                                        <p className="font-DMSans text-sm1">Argha Shipan Sarker</p>
                                    </td>
                                    <td>
                                        <p className="font-DMSans text-sm1 text-logobarElementBG">Tk.2059</p>
                                    </td>
                                    <td>
                                        <p className="font-DMSans text-sm1">Delivered</p>
                                    </td>
                                    <td>
                                        <p className="font-DMSans text-sm1 hover:text-logobarElementBG font-medium">View</p>
                                    </td>
                                </tr>

                                <tr className="h-12">
                                    <td className="pl-2">
                                        <p className="font-DMSans text-sm1">171099999</p>
                                    </td>
                                    <td>
                                        <p className="font-DMSans text-sm1">12/06/18</p>
                                    </td>
                                    <td>
                                        <p className="font-DMSans text-sm1">Argha Shipan Sarker</p>
                                    </td>
                                    <td>
                                        <p className="font-DMSans text-sm1 text-logobarElementBG">Tk.1059</p>
                                    </td>
                                    <td>
                                        <p className="font-DMSans text-sm1">Complete</p>
                                    </td>
                                    <td>
                                        <p className="font-DMSans text-sm1 hover:text-logobarElementBG font-medium">View</p>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                    {/* For Responsiveness */}

                    <div className="responsive-order-list md:hidden">
                        <div className="mb-4">
                            <p className="font-Poppins font-medium text-sm mb-1">Order Id: <span className="font-DMSans text-sm1 font-normal inline-block ml-2">171081849</span></p>
                            <p className="font-Poppins font-medium text-sm mb-1">Date: <span className="font-DMSans text-sm1 font-normal inline-block ml-2">10/17/21</span></p>
                            <p className="font-Poppins font-medium text-sm mb-1">Ship To:  <span className="font-DMSans text-sm1 font-normal inline-block ml-2">Argha Shipan Sarker</span></p>
                            <p className="font-Poppins font-medium text-sm mb-1">Order Total:  <span className="font-DMSans text-sm1 font-normal inline-block ml-2">Tk.2059</span></p>
                            <p className="font-Poppins font-medium text-sm mb-1">Status:  <span className="font-DMSans text-sm1 font-normal inline-block ml-2">Delivered</span></p>
                            <p className="font-Poppins font-medium text-sm font-medium">View</p>
                        </div>
                        <hr className="mb-1" />
                        <hr className="mb-4" />
                        <div className="">
                            <p className="font-Poppins font-medium text-sm mb-1">Order Id: <span className="font-DMSans text-sm1 font-normal inline-block ml-2">171099999</span></p>
                            <p className="font-Poppins font-medium text-sm mb-1">Date: <span className="font-DMSans text-sm1 font-normal inline-block ml-2">12/06/18</span></p>
                            <p className="font-Poppins font-medium text-sm mb-1">Ship To:  <span className="font-DMSans text-sm1 font-normal inline-block ml-2">Argha Shipan Sarker</span></p>
                            <p className="font-Poppins font-medium text-sm mb-1">Order Total:  <span className="font-DMSans text-sm1 font-normal inline-block ml-2">Tk.1059</span></p>
                            <p className="font-Poppins font-medium text-sm mb-1">Status:  <span className="font-DMSans text-sm1 font-normal inline-block ml-2">Complete</span></p>
                            <p className="font-Poppins font-medium text-sm font-medium">View</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AccountSummary
