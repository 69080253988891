import React from 'react'
import Slider from "react-slick";
import Tippy from "@tippyjs/react"
import 'tippy.js/dist/tippy.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaPinterestP, FaWhatsapp } from "react-icons/fa"
import { BiMinus, BiPlus } from "react-icons/bi"

import Color1 from "../../../Assets/Images/IndividualProduct/color1.png"
import Color2 from "../../../Assets/Images/IndividualProduct/color2.png"
import Color3 from "../../../Assets/Images/IndividualProduct/color3.png"
import Color4 from "../../../Assets/Images/IndividualProduct/color4.png"
import Color5 from "../../../Assets/Images/IndividualProduct/color5.png"

import ProductImage1 from "../../../Assets/Images/IndividualProduct/ProductImage1.png"
import ProductImage2 from "../../../Assets/Images/IndividualProduct/ProductImage2.png"
import ProductImage3 from "../../../Assets/Images/IndividualProduct/ProductImage3.png"

import ShippingLogo from "../../../Assets/Images/IndividualProduct/ShippingLogo.png"
import OriginalLogo from "../../../Assets/Images/IndividualProduct/OriginalLogo.png"
import RefundLogo from "../../../Assets/Images/IndividualProduct/RefundLogo.png"

import CompanyLogo from "../../../Assets/Images/CompanyLogo.png"

const ProductInfoSection = () => {

    const images = [ProductImage1, ProductImage2, ProductImage3]

    const settings = {
        customPaging: function (i) {
            return (
                <div className="w-3/4 h-3/4">
                    <img src={images[i]} alt="Products Demo" className="h-full w-full object-contain" />
                </div>
            );
        },
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    return (
        <div className="container mx-auto pl-4 pr-4 md:pr-11 mt-20">
            <div className="grid grid-cols-12 gap-6">
                <div className="col-span-3">
                    <Slider {...settings} className="h-110">
                        <div>
                            <div className="h-85.25 bg-topBarBG flex justify-center items-center">
                                <img src={ProductImage1} alt="Products" />
                            </div>
                        </div>
                        <div>
                            <div className="h-85.25 bg-topBarBG flex justify-center items-center">
                                <img src={ProductImage2} alt="Products" />
                            </div>
                        </div>
                        <div>
                            <div className="h-85.25 bg-topBarBG flex justify-center items-center">
                                <img src={ProductImage3} alt="Products" />
                            </div>
                        </div>
                    </Slider>
                </div>

                <div className="col-span-6">
                    <p className="text-2xl text-topBarTextColor font-Poppins font-bold mb-4.75">Laboriosam nisi ut aliquid ex ea commodi consequatur</p>

                    <div className="flex items-center mb-7.5">
                        <Tippy content="4.00" placement="top">
                            <span className="inline-block">
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="far fa-star"></i>
                            </span>
                        </Tippy>
                        <p className="text-logobarElementBG text-sm font-Poppins ml-3.25">(2 reviews)</p>
                    </div>

                    <div className="flex items-center">
                        <strike className="text-base text-mutedText font-semibold font-Poppins">Tk. 2140</strike>
                        <p className="text-2xl text-logobarElementBG font-Poppins font-semibold ml-2">Tk. 1720</p>
                        <div className="flex ml-15.5">
                            {/* <button className="w-10 h-10 border-1 border-topBarVerticalSeperator rounded-2.5xl flex justify-center items-center mr-2.5 hover:bg-logobarElementBG hover:text-white text-topBarTextColor"> */}
                            <FaFacebookF className="mr-5" />
                            {/* </button> */}
                            {/* <button className="w-10 h-10 border-1 border-topBarVerticalSeperator rounded-2.5xl flex justify-center items-center mr-2.5 hover:bg-logobarElementBG hover:text-white text-topBarTextColor"> */}
                            <FaTwitter className="mr-5" />
                            {/* </button> */}
                            {/* <button className="w-10 h-10 border-1 border-topBarVerticalSeperator rounded-2.5xl flex justify-center items-center mr-2.5 hover:bg-logobarElementBG hover:text-white text-topBarTextColor"> */}
                            <FaWhatsapp className="mr-5" />
                            {/* </button> */}
                            {/* <button className="w-10 h-10 border-1 border-topBarVerticalSeperator rounded-2.5xl flex justify-center items-center mr-2.5 hover:bg-logobarElementBG hover:text-white text-topBarTextColor"> */}
                            <FaInstagram className="mr-5" />
                            {/* </button> */}
                            {/* <button className="w-10 h-10 border-1 border-topBarVerticalSeperator rounded-2.5xl flex justify-center items-center hover:bg-logobarElementBG hover:text-white text-topBarTextColor"> */}
                            <FaPinterestP className="mr-5" />
                            {/* </button> */}
                        </div>
                    </div>

                    <div className="mt-9">
                        <div className="flex items-center">
                            <p className="text-sm font-semibold font-Poppins">SKU:</p>
                            <p className="text-base font-DMSans ml-25">157522619_BD-1088358893</p>
                        </div>
                        <div className="flex items-center">
                            <p className="text-sm font-semibold font-Poppins">Availability:</p>
                            <p className="text-base font-DMSans ml-12">In stock</p>
                        </div>
                        <div className="flex items-center">
                            <p className="text-sm font-semibold font-Poppins">Brand:</p>
                            <p className="text-base font-DMSans ml-22">Nike</p>
                        </div>
                        <div className="flex items-center">
                            <p className="text-sm font-semibold font-Poppins">Category:</p>
                            <p className="text-base font-DMSans ml-15 text-logobarElementBG">Running Shoe</p>
                        </div>
                        <div className="flex items-center">
                            <p className="text-sm font-semibold font-Poppins">Main Material:</p>
                            <p className="text-base font-DMSans ml-7.75">Nylon</p>
                        </div>
                    </div>

                    <div className="flex items-center mt-9.25">
                        <p className="text-base font-Poppins font-semibold">COLORS:</p>
                        <div className="flex ml-4">
                            <div className="h-12.5 w-12.5 border-1 flex justify-center items-center mr-2" style={{ borderRadius: "50%" }}>
                                <img src={Color1} />
                            </div>
                            <div className="h-12.5 w-12.5 border-1 flex justify-center items-center mr-2" style={{ borderRadius: "50%" }}>
                                <img src={Color2} />
                            </div>
                            <div className="h-12.5 w-12.5 border-1 flex justify-center items-center mr-2" style={{ borderRadius: "50%" }}>
                                <img src={Color3} />
                            </div>
                            <div className="h-12.5 w-12.5 border-1 flex justify-center items-center mr-2" style={{ borderRadius: "50%" }}>
                                <img src={Color4} />
                            </div>
                            <div className="h-12.5 w-12.5 border-1 flex justify-center items-center" style={{ borderRadius: "50%" }}>
                                <img src={Color5} />
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center mt-6.25">
                        <p className="text-base font-Poppins font-semibold">SIZES:</p>
                        <div className="flex ml-9">
                            <div className="w-9 h-9 border-1 flex justify-center items-center mr-2" style={{ borderRadius: "50%" }}>
                                <p className="text-sm font-Poppins">39</p>
                            </div>
                            <div className="w-9 h-9 border-1 flex justify-center items-center mr-2" style={{ borderRadius: "50%" }}>
                                <p className="text-sm font-Poppins">40</p>
                            </div>
                            <div className="w-9 h-9 border-1 flex justify-center items-center mr-2" style={{ borderRadius: "50%" }}>
                                <p className="text-sm font-Poppins">41</p>
                            </div>
                            <div className="w-9 h-9 border-1 flex justify-center items-center mr-2" style={{ borderRadius: "50%" }}>
                                <p className="text-sm font-Poppins">42</p>
                            </div>
                            <div className="w-9 h-9 border-1 flex justify-center items-center" style={{ borderRadius: "50%" }}>
                                <p className="text-sm font-Poppins">43</p>
                            </div>
                        </div>
                    </div>

                    <div className="mt-9.75 flex">
                        <div className="w-25.25 h-9.25 bg-topBarBG flex justify-between items-center px-2 font-Poppins font-semibold text-sm mr-2">
                            <BiMinus />
                            <p>1</p>
                            <BiPlus />
                        </div>
                        <div className="w-36 h-9.25 bg-logobarElementBG flex justify-center items-center font-Poppins font-semibold text-sm text-white mr-2">
                            <p>ADD TO BAG</p>
                        </div>
                        <div className="h-9.25 w-9.25 bg-topBarBG flex justify-center items-center">
                            <i className="far fa-heart" style={{ color: "black" }}></i>
                        </div>
                    </div>

                    <hr style={{ width: 410 }} className="my-6" />

                    <div className="">
                        <p className="text-base font-DMSans">Nam libero tempore, cum soluta nobis est eligendi optio cum que nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repe llendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet.</p>
                    </div>

                </div>

                <div className="col-span-3">
                    <div className="h-64 bg-topBarBG p-6">
                        <div className="flex items-center">
                            <img src={ShippingLogo} alt="Shipping" style={{ width: 28, height: 18 }} className="mr-5" />
                            <p className="font-Poppins text-sm"><b>Free Shipping</b> apply to all orders over <b>Tk. 2500</b></p>
                        </div>
                        <hr className="mt-5 mb-5" />
                        <div className="flex items-center">
                            <img src={OriginalLogo} alt="Original" style={{ width: 28, height: 18 }} className="mr-5" />
                            <p className="font-Poppins text-sm"><b>100% Original</b> product from authorized seller</p>
                        </div>
                        <hr className="mt-5 mb-5" />
                        <div className="flex items-center">
                            <img src={RefundLogo} alt="Refund" style={{ width: 28, height: 18 }} className="mr-5" />
                            <p className="font-Poppins text-sm"><b>7 Day Returns</b> if you change your mind</p>
                        </div>
                    </div>

                    <div className="h-32.75 bg-topBarBG p-6 mt-5 flex items-center">
                        <div className="w-12.5 h-12.5 bg-white flex items-center justify-center pt-2" style={{ borderRadius: "50%" }}>
                            <img src={CompanyLogo} className="w-3/4 h-3/4 object-contain" />
                        </div>
                        <div className="ml-4">
                            <p className="font-Poppins text-sm">Sold by</p>
                            <p className="font-Poppins text-base font-bold mt-4">NIKE Bangladesh</p>
                            <div className="w-22 h-5.5 bg-logobarElementBG rounded-xl flex justify-center items-center mt-4">
                                <p className="font-Poppins text-xs font-semibold text-white">Go To Store</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ProductInfoSection
