import React from 'react'

import { FaFacebookF, FaTwitter, FaYoutube, FaInstagram, FaPinterestP } from "react-icons/fa"

import CompanyLogo from "../Assets/Images/CompanyLogo.png"
import CompanyName from "../Assets/Images/CompanyName.png"
import EmailIcon from "../Assets/Images/EmailIcon.png"
import HelpLineIcon from "../Assets/Images/HelpLineIcon.png"
import LocationIcon from "../Assets/Images/LocationIcon.png"
import Bkash from "../Assets/Images/bkash.png"
import CityBank from "../Assets/Images/cityBank.png"
import Discover from "../Assets/Images/discover.png"
import MasterCard from "../Assets/Images/masterCard.png"
import Nagad from "../Assets/Images/nagad.png"
import PayPal from "../Assets/Images/payPal.png"
import Rocket from "../Assets/Images/rocket.png"
import Skrill from "../Assets/Images/skrill.png"
import Visa from "../Assets/Images/visa.png"

const Footer = () => {
    return (
        <div className="container mx-auto px-4 mt-20">
            <div className="grid gap-y-8 grid-cols-1 md:grid-cols-2">
                <div>
                    <div className="flex items-center">
                        <div className="w-23 h-15 mr-4">
                            <img src={CompanyLogo} alt="Company Logo" />
                        </div>
                        <div className="w-59 h-8">
                            <img src={CompanyName} alt="Company Name" />
                        </div>
                    </div>
                    <div className="flex items-center mt-8">
                        <div className="w-5 h-7 mr-3.5">
                            <img src={LocationIcon} alt="Location Icon" className="w-full" />
                        </div>
                        <p className="font-DMSans text-topBarTextColor text-base font-medium">45, Shah Makhdum Avenue, Sector 12, Uttara, Dhaka</p>
                    </div>
                    <div className="flex items-center mt-4">
                        <div className="w-5 h-7 mr-3.5">
                            <img src={HelpLineIcon} alt="Help line Icon" className="w-full" />
                        </div>
                        <p className="font-DMSans text-topBarTextColor text-base font-medium">01713-344232, 01713-344232</p>
                    </div>
                    <div className="flex items-center mt-4">
                        <div className="w-5 h-7 mr-3.5">
                            <img src={EmailIcon} alt="Email Icon" className="w-full" />
                        </div>
                        <p className="font-DMSans text-topBarTextColor text-base font-medium">support@fashionfield.net</p>
                    </div>
                    <div className="flex mt-8">
                        <button className="w-10 h-10 border-1 border-topBarVerticalSeperator rounded-2.5xl flex justify-center items-center mr-2.5 hover:bg-logobarElementBG hover:text-white text-topBarTextColor">
                            <FaFacebookF />
                        </button>
                        <button className="w-10 h-10 border-1 border-topBarVerticalSeperator rounded-2.5xl flex justify-center items-center mr-2.5 hover:bg-logobarElementBG hover:text-white text-topBarTextColor">
                            <FaTwitter />
                        </button>
                        <button className="w-10 h-10 border-1 border-topBarVerticalSeperator rounded-2.5xl flex justify-center items-center mr-2.5 hover:bg-logobarElementBG hover:text-white text-topBarTextColor">
                            <FaYoutube />
                        </button>
                        <button className="w-10 h-10 border-1 border-topBarVerticalSeperator rounded-2.5xl flex justify-center items-center mr-2.5 hover:bg-logobarElementBG hover:text-white text-topBarTextColor">
                            <FaInstagram />
                        </button>
                        <button className="w-10 h-10 border-1 border-topBarVerticalSeperator rounded-2.5xl flex justify-center items-center hover:bg-logobarElementBG hover:text-white text-topBarTextColor">
                            <FaPinterestP />
                        </button>
                    </div>
                </div>
                <div className="grid gap-y-8 gap-x-4 grid-cols-1 md:grid-cols-3">
                    <div>
                        <p className="font-Poppins text-topBarTextColor font-semibold text-lg">Company</p>
                        <div className="w-13 h-1.5 bg-logobarElementBG rounded-lg mt-2"></div>
                        <p className="mt-6 md:mt-9"><span className="footer-text">Home</span></p>
                        <p className="mt-3"><span className="footer-text">About Us</span></p>
                        <p className="mt-3"><span className="footer-text">Contact Us</span></p>
                        <p className="mt-3"><span className="footer-text">Our Blog</span></p>
                        <p className="mt-3"><span className="footer-text">Support Center</span></p>
                        <p className="mt-3"><span className="footer-text">Privacy Policy</span></p>
                    </div>
                    <div>
                        <p className="font-Poppins text-topBarTextColor font-semibold text-lg">Support</p>
                        <div className="w-13 h-1.5 bg-logobarElementBG rounded-lg mt-2"></div>
                        <p className="mt-6 md:mt-9"><span className="footer-text">Delivery Product</span></p>
                        <p className="mt-3"><span className="footer-text">Order Tracking</span></p>
                        <p className="mt-3"><span className="footer-text">Return & Refund</span></p>
                        <p className="mt-3"><span className="footer-text">Shipping & Replacement</span></p>
                        <p className="mt-3"><span className="footer-text">Help Center</span></p>
                        <p className="mt-3"><span className="footer-text">Live Chat</span></p>
                    </div>
                    <div>
                        <p className="font-Poppins text-topBarTextColor font-semibold text-lg">Useful Links</p>
                        <div className="w-13 h-1.5 bg-logobarElementBG rounded-lg mt-2"></div>
                        <p className="mt-6 md:mt-9"><span className="footer-text">FAQ</span></p>
                        <p className="mt-3"><span className="footer-text">Privacy Policy</span></p>
                        <p className="mt-3"><span className="footer-text">Cookie Policy</span></p>
                        <p className="mt-3"><span className="footer-text">Safe Payments</span></p>
                        <p className="mt-3"><span className="footer-text">Purchasing Policy</span></p>
                        <p className="mt-3"><span className="footer-text">Terms of Service</span></p>
                    </div>
                </div>
            </div>
            <div className="grid gap-y-4 grid-cols-1 md:grid-cols-2 mt-10">
                <div className="flex items-center justify-center md:ml-auto">
                    <img src={Bkash} alt="Bkash" className="mr-4" />
                    <img src={Nagad} alt="Bkash" className="mr-4" />
                    <img src={Rocket} alt="Bkash" className="mr-4" />
                    <img src={CityBank} alt="Bkash" className="mr-4" />
                    <img src={Visa} alt="Bkash" className="mr-4" />
                </div>
                <div className="flex items-center justify-center md:mr-auto">
                    {/* <img src={Maestro} alt="Bkash" className="mr-4" /> */}
                    <img src={MasterCard} alt="Bkash" className="mr-4" />
                    <img src={PayPal} alt="Bkash" className="mr-4" />
                    <img src={Discover} alt="Bkash" className="mr-4" />
                    {/* <img src={JCB} alt="Bkash" className="mr-4" /> */}
                    <img src={Skrill} alt="Bkash" />
                </div>
            </div>
        </div>
    )
}

export default Footer
