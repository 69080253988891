import React from 'react'
import { Link } from 'react-router-dom'

const AllCategoryMenu = props => {
    const { setDisplayAllCategory, hidden } = props
    return (
        <div className="container mx-auto px-4 bg-white h-96" style={{ boxShadow: "0px 20px 46px 0 rgba(0, 0, 0, 0.15)" }}>
            <div className="grid gap-8 grid-cols-6 px-5">
                <div className="mt-7.75 mb-7.75">
                    <p className="font-Poppins font-bold text-topBarTextColor text-base">Men</p>
                    <p className="mt-5"><span className="menu-text">Men's Clothing</span></p>
                    <p className="mt-3"><span className="menu-text">Men's Watches</span></p>
                    <Link to="/sub-category/abraham-grady/roscoe-breitenberg" className="mt-3 block"><span className="menu-text" onClick={() => setDisplayAllCategory(hidden)}>Men's Footware</span></Link>
                    <Link className="mt-3 block" to="/sub-category/abraham-grady/norene-kling"><span className="menu-text" onClick={() => setDisplayAllCategory(hidden)}>Men's Care</span></Link>
                    <p className="mt-3"><span className="menu-text">Men's Accessories</span></p>
                </div>
                <div className="mt-7.75">
                    <p className="font-Poppins font-bold text-topBarTextColor text-base">Women</p>
                    <p className="mt-5"><span className="menu-text">Women's Clothing</span></p>
                    <p className="mt-3"><span className="menu-text">Women's Footware</span></p>
                    <p className="mt-3"><span className="menu-text">Women's Watches</span></p>
                    <p className="mt-3"><span className="menu-text">Women's Accessories</span></p>
                    <p className="mt-3"><span className="menu-text">Women's Jwellery</span></p>
                    <p className="mt-3"><span className="menu-text">Women's Beauty & Care</span></p>
                </div>
                <div className="mt-7.75">
                    <p className="font-Poppins font-bold text-topBarTextColor text-base">Boys and Girls</p>
                    <p className="mt-5"><span className="menu-text">Boy's Clothing</span></p>
                    <p className="mt-3"><span className="menu-text">Girl's Clothing</span></p>
                    <p className="mt-3"><span className="menu-text">Kids Footwear</span></p>
                    <p className="mt-3"><span className="menu-text">Accessories</span></p>
                    <p className="mt-3"><span className="menu-text">Toys</span></p>
                </div>
                <div className="mt-7.75">
                    <p className="font-Poppins font-bold text-topBarTextColor text-base">Mother's Care</p>
                    <p className="mt-5"><span className="menu-text">Mother's Care</span></p>
                    <p className="mt-3"><span className="menu-text">Baby's Care</span></p>
                    <p className="mt-3"><span className="menu-text">Nursing & Feeding</span></p>
                    <p className="mt-3"><span className="menu-text">Baby Food</span></p>
                </div>
                <div className="mt-7.75">
                    <p className="font-Poppins font-bold text-topBarTextColor text-base">Personal Care</p>
                    <p className="mt-5"><span className="menu-text">Men's Care</span></p>
                    <p className="mt-3"><span className="menu-text">Women's Care</span></p>
                    <p className="mt-3"><span className="menu-text">Baby Care</span></p>
                    <p className="mt-3"><span className="menu-text">Mother's Care</span></p>
                </div>
                <div className="mt-7.75">

                </div>
            </div>
        </div>
    )
}

export default AllCategoryMenu
