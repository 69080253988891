import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from "react-redux"

import HomeAddress from './HomeAddress'
import OfficeAddress from './OfficeAddress'
import GiftAddress from './GiftAddress'

const BillingAddress = () => {

    const { token } = useSelector(state => state.authInfo)

    const [districtOptionsAxios, setDistrictOptionsAxios] = useState([])
    const [districtOptionsCustom, setDistrictOptionsCustom] = useState([])

    // getting and making the options list for district
    useEffect(() => {
        // if (token != null) {
        axios.get("/address/get-regions", {
            headers: {
                // Authorization: "Bearer " + token,
                Accept: 'application/json',
            }
        }).then(response => {
            // console.log(response.data)
            setDistrictOptionsAxios(response.data)
        }).catch(errors => {
            console.log(errors.response)
        })
        // }
    }, [])

    useEffect(() => {
        setDistrictOptionsCustom([])
        if (districtOptionsAxios.length > 0) {
            districtOptionsAxios.map((item, index) => {
                setDistrictOptionsCustom(prevState => [...prevState, { value: item.id, label: item.name }])
            })
        }

    }, [districtOptionsAxios])

    const [openHome, setOpenHome] = useState(true)
    const [openOffice, setOpenOffice] = useState(false)
    const [openGift, setOpenGift] = useState(false)

    useEffect(() => {
        if (openHome) {
            setOpenOffice(false)
            setOpenGift(false)
        }
    }, [openHome])

    useEffect(() => {
        if (openOffice) {
            setOpenHome(false)
            setOpenGift(false)
        }
    }, [openOffice])

    useEffect(() => {
        if (openGift) {
            setOpenHome(false)
            setOpenOffice(false)
        }
    }, [openGift])


    return (
        <div className="argha billing-address-pannel">
            <HomeAddress openHome={openHome} setOpenHome={setOpenHome} districtOptionsCustom={districtOptionsCustom} />
            <p className="font-Poppins font-bold text-lg mt-4 mb-4">OR</p>
            <OfficeAddress openOffice={openOffice} setOpenOffice={setOpenOffice} districtOptionsCustom={districtOptionsCustom} />
            <p className="font-Poppins font-bold text-lg mt-4 mb-4">OR</p>
            <GiftAddress openGift={openGift} setOpenGift={setOpenGift} districtOptionsCustom={districtOptionsCustom} />
        </div>
    )
}

export default BillingAddress
