import React from 'react'
import OtpInput from 'react-otp-input';
import { ImSpinner9 } from "react-icons/im"

import OTPImage from "../../../Assets/Images/RegisterAndOtp/OTP.png"

const OtpPage = props => {
    const { otp, setOtp, handleCompleteRegistrationButton, handleCompleteRegistrationButtonWithEnterPress, mobileNumber, otpStatus, loading } = props
    return (
        <div className="grid gap-4 grid-cols-1 md:grid-cols-3">
            <div className="col-span-1 flex items-center">
                <div>
                    <img src={OTPImage} alt="OTP Logo" />
                </div>
            </div>
            <div className="col-span-2">
                <div className="px-8 py-8">
                    <p className="text-2xl font-Poppins font-medium text-logobarElementBG mb-8 text-center">Two-Step Verification</p>
                    <p className="text-base font-DMSans font-normal text-topBarTextColor mb-6 text-center">Please enter the OTP (One Time Password) to verify your account. A code has been sent to <span className="font-bold">{mobileNumber}</span></p>
                    <div className={`flex justify-center ${otpStatus != "Invalid OTP" ? "mb-6" : ""}`}>
                        <div onKeyPress={event => handleCompleteRegistrationButtonWithEnterPress(event)}>
                            <OtpInput
                                value={otp}
                                onChange={otp => setOtp(otp)}
                                numInputs={4}
                                separator={<span className="px-1"></span>}
                                isInputNum={true}
                                shouldAutoFocus={true}

                                inputStyle={{
                                    border: "1px solid #252525",
                                    borderRadius: "8px",
                                    width: "40px",
                                    height: "40px",
                                    fontSize: "16px",
                                    color: "#000",
                                    fontWeight: "500",
                                    caretColor: "#252525",
                                    // marginBottom: 20
                                }}
                                focusStyle={{
                                    border: "1px solid #e5371b",
                                    outline: "none"
                                }}
                            />
                        </div>
                    </div>
                    {
                        otpStatus == "Invalid OTP" && <p className="text-xs text-logobarElementBG font-Poppins mt-2 mb-6">{otpStatus}</p>
                    }
                    {
                        loading ? (
                            <button className="flex text-sm rounded-md shadow-lg font-Poppins justify-center items-center h-10 w-full bg-logobarElementBG text-white mb-4 disabled" >
                                <ImSpinner9 className="animate-spin" />
                            </button>
                        ) : (
                            <button className="flex text-sm rounded-md shadow-lg font-Poppins justify-center items-center h-10 w-full bg-logobarElementBG text-white mb-4" onClick={handleCompleteRegistrationButton}>Continue</button>
                        )
                    }
                    <p className="font-Poppins text-base mb-4 text-center">Not recieved your code? <span className="text-logobarElementBG font-medium">Resend code</span></p>
                    <p className="font-Poppins text-xs font-medium text-logobarElementBG text-center">Register/Login with Email</p>
                </div>
            </div>
        </div>
    )
}

export default OtpPage
