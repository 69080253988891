import React, { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { IoMdMenu } from "react-icons/io"
import { BsArrowLeft, BsArrowRight } from "react-icons/bs"

import FilteringSidebar from '../Components/PageComponents/RunningShoePage/FilteringSidebar'
import FilteredProducts from '../Components/PageComponents/RunningShoePage/FilteredProducts'

const options = [
    { value: 1, label: "Default Sorting" },
    { value: 2, label: "New Arrival" },
    { value: 3, label: "Top Rated" },
    { value: 4, label: "Best Seller" },
    { value: 5, label: "Price Low to High" },
    { value: 6, label: "Price High to Low" },
]

const styles = {
    option: (provided, state) => ({
        ...provided,
        fontSize: 16,
        fontFamily: "Poppins",
        color: state.isSelected ? "white" : "#252525",
        backgroundColor: state.isSelected ? "#e5371b" : "",
        '&:hover': { backgroundColor: state.isSelected ? "" : '#f6f6f6' },
    }),
    singleValue: (provided, state) => ({
        ...provided,
        fontSize: 16,
        fontFamily: "Poppins",
        color: "#252525",
    }),
    placeholder: (provided, state) => ({
        ...provided,
        fontSize: 16,
        fontFamily: "Poppins",
        color: "#252525",
    }),
    control: (provided, state) => ({
        ...provided,
        boxShadow: "none",
        backgroundColor: "#f6f6f6",
        width: 200,
        border: '1px solid #e5371b', // default border color
        '&:hover': { borderColor: '#e5371b' }, // border style on hover
        // You can also use state.isFocused to conditionally style based on the focus state
    })
}

const RunningShoePage = () => {

    const [selectedSorting, setSelectedSorting] = useState({ value: 1, label: "Default Sorting" })
    const [open, setOpen] = useState(false)
    const testActive = "fixed top-0 left-0 h-screen w-3/4 bg-white transform transition-all duration-500 z-10 bg-white overflow-y-scroll pb-8 shadow-2xl md:hidden p-4"
    const testHidden = "absolute top-0 left-0 h-screen w-40 transform transition-all duration-500 opacity-0 -translate-x-114 z-10"
    const hamburgerRef = useRef(null)
    const sidebarRef = useRef(null)

    const closeSidebarWhenClickedOutside = (ref, hamburgerRef) => {
        // console.log(ref.current)

        // console.log(ref.current)
        function handleClickOutside(event) {
            // console.log(ref.current.contains(event.target))
            if (ref.current && !ref.current.contains(event.target) && !hamburgerRef.current.contains(event.target)) {
                setOpen(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }

    closeSidebarWhenClickedOutside(sidebarRef, hamburgerRef)

    const { pathname } = useLocation()
    useEffect(() => {
        window.scrollTo(0, 0)
        setOpen(false)
    }, [pathname])

    return (
        <div className="container mx-auto pl-4 pr-4 md:pr-7">
            <div className="container mx-auto mt-8">
                <ol className="list-reset flex text-sm font-medium font-Poppins">
                    <li><Link to="/" className="text-logobarElementBG">Home</Link></li>
                    <li><span className="mx-2 text-mutedText">/</span></li>
                    <li><Link to="/men" className="text-logobarElementBG">Men</Link></li>
                    <li><span className="mx-2 text-mutedText">/</span></li>
                    <li><Link to="/menFootWear" className="text-logobarElementBG">Footwear</Link></li>
                    <li><span className="mx-2 text-mutedText">/</span></li>
                    <li className="text-mutedText">Running Shoes</li>
                </ol>
            </div>
            <div className="grid grid-cols-4 gap-x-7.5 mt-5">

                <div className="hidden md:block">
                    <FilteringSidebar />
                </div>

                <div className="col-span-4 md:col-span-3">
                    <p className="font-Poppins font-semibold text-3xl text-sliderHeading mb-3.25">Running Shoes</p>
                    <div className="w-12.5 h-1.5 bg-logobarElementBG rounded-lg mb-7.5"></div>
                    <div className="h-12.5 md:bg-topBarBG mb-7.5 md:px-5 flex justify-between items-center select-sorting">
                        <p className="font-Poppins font-semibold text-base text-topBarTextColor hidden md:block">Showing 1-30 of 152 products</p>
                        <Select
                            value={selectedSorting}
                            onChange={option => setSelectedSorting(option)}
                            options={options}
                            placeholder="Select Sorting"
                            isSearchable={false}
                            isClearable={false}
                            className="focus:outline-none"
                            styles={styles}
                        />
                        <button
                            className="flex flex-col h-10 w-10 justify-center items-center group md:hidden border-2"
                            onClick={() => setOpen((prevState) => !prevState)}
                            ref={hamburgerRef}
                        >
                            <IoMdMenu size={35} color="#222222" />
                        </button>
                    </div>
                    <div className={open ? testActive : testHidden} ref={sidebarRef}>
                        <FilteringSidebar />
                    </div>
                    <FilteredProducts />
                </div>

            </div>
            <div className="pagenation mt-8 mb-11">
                <div className="flex items-center justify-end">
                    <div className="flex items-center mr-4">
                        <BsArrowLeft className="mr-2 text-mutedText" />
                        <p className="font-DMSans text-base text-mutedText">Prev</p>
                    </div>
                    <div className="flex items-center justify-center w-10 h-10 bg-logobarElementBG mr-4 cursor-pointer" style={{ borderRadius: "50%" }}>
                        <p className="font-DMSans text-base text-white">1</p>
                    </div>
                    <div className="flex items-center justify-center w-10 h-10 mr-4 border-1 border-transparent hover:border-gray-600 cursor-pointer" style={{ borderRadius: "50%" }}>
                        <p className="font-DMSans text-base">2</p>
                    </div>
                    <div className="flex items-center justify-center w-10 h-10 mr-4 border-1 border-transparent hover:border-gray-600 cursor-pointer" style={{ borderRadius: "50%" }}>
                        <p className="font-DMSans text-base">3</p>
                    </div>
                    <div className="flex items-center justify-center w-10 h-10 mr-4 border-1 border-transparent hover:border-gray-600 cursor-pointer" style={{ borderRadius: "50%" }}>
                        <p className="font-DMSans text-base">4</p>
                    </div>
                    <div className="flex items-center justify-center w-10 h-10 mr-4 border-1 border-transparent hover:border-gray-600 cursor-pointer" style={{ borderRadius: "50%" }}>
                        <p className="font-DMSans text-base">5</p>
                    </div>
                    <div className="flex items-center cursor-pointer">
                        <p className="font-DMSans text-base">Next</p>
                        <BsArrowRight className="ml-2" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RunningShoePage
