import React, { useEffect, useState } from 'react'
import { useLocation, Link, useParams } from "react-router-dom"
import axios from 'axios'

import HeroSlider from '../Components/PageComponents/MenCategoryPage/HeroSlider'
import DealsOfTheDay from '../Components/PageComponents/MenCategoryPage/DealsOfTheDay'
import ShopByCategory from '../Components/PageComponents/MenCategoryPage/ShopByCategory'
import MenBestSeller from '../Components/PageComponents/MenCategoryPage/MenBestSeller'
import MenFirstBanner from '../Components/PageComponents/MenCategoryPage/MenFirstBanner'
import MenShirt from '../Components/PageComponents/MenCategoryPage/MenShirt'
import MenTShirt from '../Components/PageComponents/MenCategoryPage/MenTShirt'
import MenPoloShirt from '../Components/PageComponents/MenCategoryPage/MenPoloShirt'
import MenPants from '../Components/PageComponents/MenCategoryPage/MenPants'
import MenShoe from '../Components/PageComponents/MenCategoryPage/MenShoe'
import MenSecondBanner from '../Components/PageComponents/MenCategoryPage/MenSecondBanner'
import FashionAccessoriesTab from '../Components/PageComponents/MenCategoryPage/FashionAccessoriesTab'
import TopBrands from '../Components/PageComponents/HomePage/TopBrands'

const MenCategoryPage = () => {

    const { pathname } = useLocation()

    const { slug } = useParams()

    // console.log(slug)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    const [subCategory, setSubCategory] = useState(null)

    useEffect(() => {
        axios.get(`/categories/${slug}/slider`, {
            headers: {
                'Accept': 'application/json',
            }
        }).then(response => {
            // console.log(response.data)
            setSubCategory(response.data.sub_categories)
        }).catch(errors => {
            console.log(errors)
        })
    }, [slug])

    useEffect(() => {
        // console.log(subCategory)
    }, [subCategory])

    return (
        <div>
            <HeroSlider />
            <div className="container mx-auto px-4 mt-6">
                <ol className="list-reset flex text-sm font-medium font-Poppins">
                    <li><Link to="/" className="text-logobarElementBG">Home</Link></li>
                    <li><span className="mx-2 text-mutedText">/</span></li>
                    <li className="text-mutedText">Men</li>
                </ol>
            </div>
            <DealsOfTheDay />
            <ShopByCategory />
            <MenBestSeller />
            <MenFirstBanner />


            {
                subCategory && subCategory.map((item, index) => (
                    <div key={index}>
                        <MenShirt item={item} />
                    </div>
                ))
            }


            {/* <MenTShirt /> */}
            {/* <MenPoloShirt /> */}
            {/* <MenPants /> */}
            {/* <MenShoe /> */}
            <MenSecondBanner />
            <FashionAccessoriesTab />
            <TopBrands />
        </div>
    )
}

export default MenCategoryPage
