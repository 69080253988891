import React from 'react'
import Image1 from "../../../../Assets/Images/MenFootwear/FootwearSB1.png"
import Image2 from "../../../../Assets/Images/MenFootwear/FootwearSB2.png"
import Image3 from "../../../../Assets/Images/MenFootwear/FootwearSB3.png"

const FootwearSecondBanner = () => {
    return (
        <div className="container mx-auto px-4 mt-18">
            <div className="md:pr-7 grid grid-cols-1 md:grid-cols-4 gap-4">
                <div className="h-106 md:h-82.5 overflow-hidden border-1 hover:border-mutedText border-transparent hover:shadow-md transition-all ease-linear duration-300">
                    <img src={Image1} className="w-full h-full transform scale-101 hover:scale-102 cursor-pointer transition-all ease-linear duration-300" alt="Banners" />
                </div>
                <div className="md:col-span-2 h-59 md:h-82.5 overflow-hidden border-1 hover:border-mutedText border-transparent hover:shadow-md transition-all ease-linear duration-300">
                    <img src={Image2} className="w-full h-full transform scale-101 hover:scale-102 cursor-pointer transition-all ease-linear duration-300" alt="Banners" />
                </div>
                <div className="h-106 md:h-82.5 overflow-hidden border-1 hover:border-mutedText border-transparent hover:shadow-md transition-all ease-linear duration-300">
                    <img src={Image3} className="w-full h-full transform scale-101 hover:scale-102 cursor-pointer transition-all ease-linear duration-300" alt="Banners" />
                </div>
            </div>
        </div>
    )
}

export default FootwearSecondBanner
