import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios'

import { useHistory } from "react-router-dom"

import { removingAllProductsFromCartAfterCheckout, calculatingTotalPrice, calculatingTotalCartProducts } from "../../../ReduxStore/CartSystem/CartStore"
import { addingShippingAddress, addingShippingAddressType, addingShippingCity } from "../../../ReduxStore/CheckOutSystem/CheckOutStore"

const OrderDetailsCheckout = () => {

    const { cartArray, cartTotalPrice } = useSelector(state => state.testCart)
    const { token } = useSelector(state => state.authInfo)

    const history = useHistory()

    const dispatch = useDispatch()

    const { shippingAddress, shippingAddressType, shippingCity } = useSelector(state => state.checkOutData)

    const [shippingDetails, setShippingDetails] = useState(null)

    console.log(shippingAddress, shippingAddressType)

    useEffect(() => {
        // console.log(cartArray)
    }, [cartArray])

    useEffect(() => {
        // console.log("hello")
        if (shippingCity != null) {
            axios.get(`/shipping-charge/${shippingCity}`, {
                headers: {
                    // Authorization: "Bearer " + token,
                    Accept: 'application/json',
                }
            }).then(response => {
                console.log(response)
                setShippingDetails(response.data[0])
            }).catch(errors => {
                console.log(errors.response)
            })
        }
    }, [shippingCity])

    const handlePlacingOrder = () => {
        console.log("hello")
        if (shippingAddress != null && shippingDetails != null && cartTotalPrice != 0 && cartArray.length != 0) {
            console.log("okk")
            const data = {
                name: shippingAddress.name,
                phone: shippingAddress.phone,
                region: shippingAddress.region,
                city: shippingAddress.city,
                area: shippingAddress.area,
                address: shippingAddress.address,
                customer_note: shippingAddress?.customer_note,
                company_name: shippingAddress?.company_name == undefined ? "" : shippingAddress?.company_name,
                designation: shippingAddress?.designation == undefined ? "" : shippingAddress?.designation,
                department: shippingAddress?.department == undefined ? "" : shippingAddress?.department,
                sender_name: shippingAddress?.sender_name == undefined ? "" : shippingAddress?.sender_name,
                sub_total: cartTotalPrice,
                shipping_total: shippingDetails.charge,
                total: parseInt(cartTotalPrice) + parseInt(shippingDetails.charge),
                carts: cartArray
            }

            console.log(data)

            axios.post("/order", data, {
                headers: {
                    // Authorization: "Bearer " + token,
                    Accept: 'application/json',
                }
            }).then(response => {
                console.log(response)

                dispatch(removingAllProductsFromCartAfterCheckout())
                dispatch(calculatingTotalPrice())
                dispatch(calculatingTotalCartProducts())

                dispatch(addingShippingAddress(null))
                dispatch(addingShippingAddressType(null))
                dispatch(addingShippingCity(null))

                localStorage.setItem("cart", "[]")

                history.push(`/order/${response.data.order.order_number}`)

            }).catch(errors => {
                console.log(errors.response)
            })
        }
    }


    return (
        <div className="bg-topBarBG px-4 pb-8">

            <p className="font-Poppins font-semibold text-xl uppercase mb-4 text-center pt-4">Order details</p>

            <div className="bg-white p-4 mb-4">

                <div className="grid grid-cols-12 pb-2 px-2 border-b-2">

                    <div className="col-span-9">
                        <p className="font-Poppins font-medium text-lg uppercase">product</p>
                    </div>

                    <div className="col-span-3">
                        <p className="font-Poppins font-medium text-lg uppercase">total</p>
                    </div>

                </div>

                {
                    cartArray.map((item, index) => (
                        <div className="px-2 border-b-1 py-3 product-info" key={index}>

                            <div className="grid grid-cols-12">

                                <div className="col-span-9">

                                    <div className="grid grid-cols-12 gap-2">

                                        <div className="col-span-8">
                                            <p className="font-DMSans font-normal text-sm line-clamp-2">{item.productName}</p>
                                        </div>

                                        <div className="col-span-4">
                                            <div className="h-full flex items-center">
                                                <p className="font-DMSans font-normal text-sm">x {item.productQuantity}</p>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="col-span-3">
                                    <div className="h-full flex items-center">
                                        <p className="font-DMSans font-normal text-sm">Tk. {item.productQuantity * item.productPrice}</p>
                                    </div>
                                </div>

                            </div>

                        </div>
                    ))
                }

                <div className="grid grid-cols-12 py-3 px-2 border-b-2">

                    <div className="col-span-9">
                        <p className="font-DMSans font-normal text-base">Sub-Total</p>
                    </div>

                    <div className="col-span-3">
                        <p className="font-DMSans font-normal text-base">Tk. {cartTotalPrice}</p>
                    </div>

                </div>

                <div className="grid grid-cols-12 py-3 px-2 border-b-2">

                    <div className="col-span-9">
                        <div className="grid grid-cols-12">

                            <div className="col-span-6">
                                <p className="font-DMSans font-normal text-base">Shipping</p>
                            </div>

                            <div className="col-span-6">
                                {shippingDetails && <p className="font-DMSans font-normal text-base">{shippingDetails?.name}</p>}
                            </div>

                        </div>

                    </div>

                    <div className="col-span-3">
                        {
                            shippingDetails && <p className="font-DMSans font-normal text-base">Tk. {shippingDetails?.charge}</p>
                        }
                    </div>

                </div>

                <div className="grid grid-cols-12 py-3 px-2 border-b-2">

                    <div className="col-span-9">
                        <p className="font-DMSans font-normal text-base">In-Total</p>
                    </div>

                    <div className="col-span-3">
                        {
                            shippingDetails && <p className="font-DMSans font-normal text-base">Tk. {parseInt(cartTotalPrice) + parseInt(shippingDetails?.charge)}</p>
                        }
                    </div>

                </div>

                {
                    shippingAddress && <div className="address-details px-2 mt-3">
                        <p className="font-DMSans font-normal text-base mb-2">Shipping To: <b>{shippingAddressType}</b></p>

                        {
                            shippingAddressType == "Gift Address" && (
                                <div>
                                    <p className="font-DMSans font-normal text-base mb-1">Sender's Name: <b>{shippingAddress.sender_name == "" ? "Anonymous" : shippingAddress.sender_name}</b></p>
                                    <p className="font-DMSans font-normal text-base mb-1 text-logobarElementBG">Recipient's Information :</p>
                                </div>
                            )
                        }

                        <p className="font-DMSans font-normal text-base mb-1">Name: <b>{shippingAddress.name}</b></p>
                        <p className="font-DMSans font-normal text-base mb-1">Mobile: <b>{shippingAddress.phone}</b></p>
                        {
                            shippingAddress?.company_name ? <p className="font-DMSans font-normal text-base mb-1">Company Name: <b>{shippingAddress?.company_name}</b></p> : ""
                        }
                        {
                            shippingAddress?.department ? <p className="font-DMSans font-normal text-base mb-1">Department Name: <b>{shippingAddress?.department}</b></p> : ""
                        }
                        {
                            shippingAddress?.designation ? <p className="font-DMSans font-normal text-base mb-1">Designation: <b>{shippingAddress?.designation}</b></p> : ""
                        }
                        <p className="font-DMSans font-normal text-base mb-1">Region: <b>{shippingAddress.region}</b></p>
                        <p className="font-DMSans font-normal text-base mb-1">City: <b>{shippingAddress.city}</b></p>
                        <p className="font-DMSans font-normal text-base mb-1">Area: <b>{shippingAddress.area}</b></p>
                        <p className="font-DMSans font-normal text-base mb-1">Street Address: <b>{shippingAddress.address}</b></p>
                        <p className="font-DMSans font-normal text-base">Customer Note: {shippingAddress.customer_note == "" ? "Nothing" : shippingAddress.customer_note}</p>

                    </div>
                }

            </div>

            <div className="w-full h-10 bg-logobarElementBG rounded flex justify-center items-center cursor-pointer mt-8 mb-4" onClick={handlePlacingOrder}>
                <p className="font-Poppins font-semibold text-xl text-white">PLACE ORDER</p>
            </div>

        </div>
    )
}

export default OrderDetailsCheckout
