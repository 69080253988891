import React from 'react'

import ProductInfoSection from '../Components/PageComponents/IndividualProduct/ProductInfoSection'
import ProductDescriptionSection from '../Components/PageComponents/IndividualProduct/ProductDescriptionSection'
import SimilarProducts from '../Components/PageComponents/IndividualProduct/SimilarProducts'
import RecentlyViewed from '../Components/PageComponents/IndividualProduct/RecentlyViewed'

const IndividualProduct = () => {
    return (
        <div>
            <ProductInfoSection />
            <ProductDescriptionSection />
            <SimilarProducts />
            <RecentlyViewed />
        </div>
    )
}

export default IndividualProduct
