import React, { useState } from 'react'
import { Link } from "react-router-dom"
import Tippy from "@tippyjs/react"
import 'tippy.js/dist/tippy.css';

// import Image1 from "../../../Assets/Images/MenFootwear/MultiCarousel1.png"

import Image1 from "../../../Assets/Images/FilteredImage/FilteredImage1.png"
import Image2 from "../../../Assets/Images/FilteredImage/FilteredImage2.png"
import Image3 from "../../../Assets/Images/FilteredImage/FilteredImage3.png"
import Image4 from "../../../Assets/Images/FilteredImage/FilteredImage4.png"
import Image5 from "../../../Assets/Images/FilteredImage/FilteredImage5.png"
import Image6 from "../../../Assets/Images/FilteredImage/FilteredImage6.png"
import Image7 from "../../../Assets/Images/FilteredImage/FilteredImage7.png"
import Image8 from "../../../Assets/Images/FilteredImage/FilteredImage8.png"
import Image9 from "../../../Assets/Images/FilteredImage/FilteredImage9.png"
import Image10 from "../../../Assets/Images/FilteredImage/FilteredImage10.png"
import Image11 from "../../../Assets/Images/FilteredImage/FilteredImage11.png"
import Image12 from "../../../Assets/Images/FilteredImage/FilteredImage12.png"
import Image13 from "../../../Assets/Images/FilteredImage/FilteredImage13.png"
import Image14 from "../../../Assets/Images/FilteredImage/FilteredImage14.png"
import Image15 from "../../../Assets/Images/FilteredImage/FilteredImage15.png"
import Image16 from "../../../Assets/Images/FilteredImage/FilteredImage16.png"
import Image17 from "../../../Assets/Images/FilteredImage/FilteredImage17.png"
import Image18 from "../../../Assets/Images/FilteredImage/FilteredImage18.png"
import Image19 from "../../../Assets/Images/FilteredImage/FilteredImage19.png"
import Image20 from "../../../Assets/Images/FilteredImage/FilteredImage20.png"
import Image21 from "../../../Assets/Images/FilteredImage/FilteredImage18.png"
import Image22 from "../../../Assets/Images/FilteredImage/FilteredImage22.png"
import Image23 from "../../../Assets/Images/FilteredImage/FilteredImage23.png"
import Image24 from "../../../Assets/Images/FilteredImage/FilteredImage24.png"
import Image25 from "../../../Assets/Images/FilteredImage/FilteredImage25.png"
import Image26 from "../../../Assets/Images/FilteredImage/FilteredImage26.png"
import Image27 from "../../../Assets/Images/FilteredImage/FilteredImage27.png"
import Image28 from "../../../Assets/Images/FilteredImage/FilteredImage28.png"
import Image29 from "../../../Assets/Images/FilteredImage/FilteredImage29.png"
import Image30 from "../../../Assets/Images/FilteredImage/FilteredImage30.png"

import TestModal from '../../ReuseableComponents/TestModal';

const data = [
    {
        image: Image1,
        category: "Running Shoes",
        name: "Dolore magnam aliqua qua erat voluptatem ut enim",
        newPrice: 1180
    },
    {
        image: Image2,
        category: "Running Shoes",
        name: "Minima veniam quis nostru exercitationem ullam corporis",
        newPrice: 1530
    },
    {
        image: Image3,
        category: "Running Shoes",
        name: "Laboriosam nisi ut aliquid ex ea commodi consequatur",
        newPrice: 1720
    },
    {
        image: Image4,
        category: "Running Shoes",
        name: "Nihil impedit quo minus idern quod maxime placeat face possimus, omnis",
        newPrice: 1150
    },
    {
        image: Image5,
        category: "Running Shoes",
        name: "Voluptas assumenda estenm omnis dolor repellendus fre Temporibus autem rerum necessit",
        newPrice: 1820
    },
    {
        image: Image6,
        category: "Running Shoes",
        name: "Quibusdam et aut officiis rety debitis aut rerum necessita fretynecessitatibus saepe eveniet ut et voluptates repudiandaemolestiae",
        newPrice: 1460
    },
    {
        image: Image7,
        category: "Running Shoes",
        name: "Nam libero tempore cum soluta nobis est editun",
        newPrice: 2065
    },
    {
        image: Image8,
        category: "Running Shoes",
        name: "Eligendi optio cumque nihil impedit quo minus id quod",
        newPrice: 1890
    },
    {
        image: Image9,
        category: "Running Shoes",
        name: "Maxime placeat facere possimus, omnis voluptas",
        newPrice: 2080
    },
    {
        image: Image10,
        category: "Running Shoes",
        name: "Nam libero tempore cum soluta nobis est editun",
        newPrice: 1260
    },
    {
        image: Image11,
        category: "Running Shoes",
        name: "Eligendi optio cumque nihil impedit quo minus id quod",
        newPrice: 1125
    },
    {
        image: Image12,
        category: "Running Shoes",
        name: "Maxime placeat facere possimus, omnis voluptas",
        newPrice: 1490
    },
    {
        image: Image13,
        category: "Running Shoes",
        name: "Dolore magnam aliqua qua erat voluptatem ut enim",
        newPrice: 1455
    },
    {
        image: Image14,
        category: "Running Shoes",
        name: "Minima veniam quis nostru exercitationem ullam corporis",
        newPrice: 1980
    },
    {
        image: Image15,
        category: "Running Shoes",
        name: "Laboriosam nisi ut aliquid ex ea commodi consequatur",
        newPrice: 1865
    },
    {
        image: Image16,
        category: "Running Shoes",
        name: "Autem vel eum iure reprehen qui in ea voluptate velit",
        newPrice: 1660
    },
    {
        image: Image17,
        category: "Running Shoes",
        name: "Temporibus autem quibusda et aut officiis debitis aut rerum necessit",
        newPrice: 1545
    },
    {
        image: Image18,
        category: "Running Shoes",
        name: "Omnis voluptas assumenda est omnis dolor repellendus",
        newPrice: 1730
    },
    {
        image: Image19,
        category: "Running Shoes",
        name: "Nihil impedit quo minus idern quod maxime placeat face possimus, omnis",
        newPrice: 2050
    },
    {
        image: Image20,
        category: "Running Shoes",
        name: "Voluptas assumenda estenm omnis dolor repellendus fre Temporibus autem rerum necessit",
        newPrice: 1365
    },
    {
        image: Image21,
        category: "Running Shoes",
        name: "Quibusdam et aut officiis rety debitis aut rerum necessita fretynecessitatibus saepe eveniet ut et voluptates repudiandaemolestiae",
        newPrice: 1830
    },
    {
        image: Image22,
        category: "Running Shoes",
        name: "Nam libero tempore cum soluta nobis est editun",
        newPrice: 1825
    },
    {
        image: Image23,
        category: "Running Shoes",
        name: "Eligendi optio cumque nihil impedit quo minus id quod",
        newPrice: 2099
    },
    {
        image: Image24,
        category: "Running Shoes",
        name: "Maxime placeat facere possimus, omnis voluptas",
        newPrice: 1955
    },
    {
        image: Image25,
        category: "Running Shoes",
        name: "Nam libero tempore cum soluta nobis est editun",
        newPrice: 1590
    },
    {
        image: Image26,
        category: "Running Shoes",
        name: "Eligendi optio cumque nihil impedit quo minus id quod",
        newPrice: 1750
    },
    {
        image: Image27,
        category: "Running Shoes",
        name: "Maxime placeat facere possimus, omnis voluptas",
        newPrice: 1470
    },
    {
        image: Image28,
        category: "Running Shoes",
        name: "Maxime placeat facere possimus, omnis voluptas",
        newPrice: 1490
    },
    {
        image: Image29,
        category: "Running Shoes",
        name: "Dolore magnam aliqua qua erat voluptatem ut enim",
        newPrice: 1455
    },
    {
        image: Image30,
        category: "Running Shoes",
        name: "Minima veniam quis nostru exercitationem ullam corporis",
        newPrice: 1980
    },
]

const FilteredProducts = () => {

    const [modal, setModal] = useState(false)
    const [indexValue, setIndex] = useState(null)

    const commonCarouselQuickViewHandle = (item, index) => {
        setModal(true)
        setIndex(index)
        console.log(item, index)
    }
    return (
        <div className="argha">
            <div className="flex flex-wrap justify-center md:justify-between">
                {
                    data.map((item, index) => (
                        <div className="flex justify-center md:justify-start transition-all ease-linear duration-300 mb-12.5" key={index}>
                            <div className="w-67">
                                <div className="w-full h-83 flex justify-center items-center bg-topBarBG relative border-1 hover:border-mutedText border-transparent hover:shadow-lg transition-all ease-linear duration-300 quickView-parent">
                                    <div className="quickView group" onClick={() => commonCarouselQuickViewHandle(item, index)}>
                                        <p className="font-Poppins font-bold text-white text-base">Quick View</p>
                                    </div>
                                    <div className={`h-full w-full flex justify-center items-center transform scale-101 hover:scale-102 transition-all ease-linear duration-300 image-scale`}>
                                        <img src={item.image} alt="Section of different products" />
                                    </div>
                                </div>
                                <div className="mt-6 h-40 flex flex-col">
                                    <p className="mb-2"><span className="font-DMSans text-xs text-commonCarouselMutedText mb-3 hover:text-topBarTextColor">{item.category}</span></p>
                                    <Link className="font-Poppins font-semibold text-base text-topBarTextColor hover:text-logobarElementBG line-clamp-2" to="/product">{item.name}</Link>
                                    <div className="flex items-center mt-auto">
                                        <div>
                                            <Tippy content="4.00" placement="right">
                                                <span className="mb-3 inline-block">
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                    <i className="far fa-star"></i>
                                                </span>
                                            </Tippy>
                                            <div className="flex items-center md:flex-col md:items-start lg:items-center  lg:flex-row">
                                                <p className="font-Poppins text-base font-semibold text-logobarElementBG">TK. {item.newPrice}</p>
                                            </div>
                                        </div>
                                        <div className="ml-auto flex">
                                            <button className="w-10 h-10 bg-timeBG rounded-2.5xl flex justify-center items-center mr-4 hover:bg-logobarElementBG group">
                                                <i className="far fa-heart group-hover:text-white"></i>
                                            </button>
                                            <button className="w-10 h-10 bg-timeBG rounded-2.5xl flex justify-center items-center hover:bg-logobarElementBG group">
                                                <i className="far fa-shopping-bag group-hover:text-white"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            {modal && <TestModal modal={modal} setModal={setModal} data={data} indexValue={indexValue} />}
        </div>
    )
}

export default FilteredProducts
