import React from 'react'
import Tippy from "@tippyjs/react"
import 'tippy.js/dist/tippy.css';

const ReviewSummary = () => {
    return (
        <div className="reviews">
            <div className="border-1 border-borderColor px-4 py-4 rounded-lg">
                <div className="hidden md:block">
                    <table className="w-full table-fixed">
                        <thead className="">
                            <tr className="border-b h-12">
                                <th className="font-Poppins font-medium text-sm w-1/5 text-left pl-2">Created</th>
                                <th className="font-Poppins font-medium text-sm w-2/5 text-left">Product Name</th>
                                <th className="font-Poppins font-medium text-sm w-1/5 text-left">Rating</th>
                                <th className="font-Poppins font-medium text-sm w-2/5 text-left">Review</th>
                                <th className="font-Poppins font-medium text-sm w-1/5 text-left">Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr className="border-b h-12">
                                <td className="pl-2">
                                    <p className="font-DMSans text-sm1">11/16/21</p>
                                </td>
                                <td>
                                    <p className="font-DMSans text-sm1 line-clamp-1">Xiaomi Basic In-Ear Headphone</p>
                                </td>
                                <td>
                                    <Tippy content="4.00" placement="top">
                                        <span className="inline-block">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="far fa-star"></i>
                                        </span>
                                    </Tippy>
                                </td>
                                <td className="pr-3">
                                    <p className="font-DMSans text-sm1 line-clamp-1">Speak, modern and cheap!
                                        I needed a new pair of earphones quickly. So I purchased these as I am familiar with Xiaomi and its products. I am happy I did. For the price, the headphones are amazing. They look very nice, the cable is high quality and the paid sound great.

                                        I’m not an audiophile but I thought they sounded very good, a lot better than other earbuds I have had previously. There isn’t anything wrong with me with the price.

                                        Really good pair of earbuds. Highly recommend.</p>
                                </td>
                                <td>
                                    <p className="font-DMSans text-sm1 hover:text-logobarElementBG font-medium cursor-pointer">View</p>
                                </td>
                            </tr>

                            <tr className="h-12">
                                <td className="pl-2">
                                    <p className="font-DMSans text-sm1">11/16/21</p>
                                </td>
                                <td>
                                    <p className="font-DMSans text-sm1 line-clamp-1">Haylou LS02 Smart Watch Global Version</p>
                                </td>
                                <td>
                                    <Tippy content="4.00" placement="top">
                                        <span className="inline-block">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="far fa-star"></i>
                                        </span>
                                    </Tippy>
                                </td>
                                <td className="pr-3">
                                    <p className="font-DMSans text-sm1 line-clamp-1">Comfortable wearing and excellent for water repellent.
                                        The design of the watch is nice and doesn’t feel cheap. It has a nice weight to it and is big enough on the wrist where it seems nice and sturdy. On top of that, it looks like a nicer end watch until you tap the screen and see that it is a smartwatch.

                                        The battery life on this watch is incredible. I have had it for a week since it was ordered to my house and it still is running without me having to charge it yet!

                                        This watch has an accurate blood pressure monitor, sleep monitor, counts your steps, alerts you to incoming calls and notifications from any apps including your messages, and is water-resistant.

                                        In conclusion for the price, it is an incredible smartwatch if you are looking for a cheaper alternative to the big-name smartwatches. It does everything they can do and then some. It also beats them in battery life. I would rate the overall experience as excellent</p>
                                </td>
                                <td>
                                    <p className="font-DMSans text-sm1 hover:text-logobarElementBG font-medium cursor-pointer">View</p>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                {/* For Responsiveness */}

                <div className="responsive-order-list md:hidden">
                    <div className="mb-4">
                        <p className="font-Poppins font-medium text-sm mb-1">Created: <span className="font-DMSans text-sm1 font-normal inline-block ml-2">11/16/21</span></p>

                        <div className="flex items-center">
                            <p className="font-Poppins font-medium text-sm mb-1 whitespace-nowrap">Product Name:</p>
                            <p className="font-DMSans text-sm1 font-normal line-clamp-1 ml-2">
                                Xiaomi Basic In-Ear Headphone
                            </p>
                        </div>
                        <p className="font-Poppins font-medium text-sm mb-1">Rating:
                            <Tippy content="4.00" placement="top">
                                <span className="inline-block ml-2">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="far fa-star"></i>
                                </span>
                            </Tippy>
                        </p>


                        <div className="flex items-center">
                            <p className="font-Poppins font-medium text-sm mb-1">Review:</p>
                            <p className="font-DMSans text-sm1 font-normal line-clamp-1 ml-2">
                                Speak, modern and cheap!
                                I needed a new pair of earphones quickly. So I purchased these as I am familiar with Xiaomi and its products. I am happy I did. For the price, the headphones are amazing. They look very nice, the cable is high quality and the paid sound great.

                                I’m not an audiophile but I thought they sounded very good, a lot better than other earbuds I have had previously. There isn’t anything wrong with me with the price.

                                Really good pair of earbuds. Highly recommend.
                            </p>
                        </div>

                        <p className="font-Poppins font-medium text-sm font-medium">View</p>
                    </div>
                    <hr className="mb-1" />
                    <hr className="mb-4" />
                    <div className="">
                        <p className="font-Poppins font-medium text-sm mb-1">Created: <span className="font-DMSans text-sm1 font-normal inline-block ml-2">11/16/21</span></p>
                        <div className="flex items-center">
                            <p className="font-Poppins font-medium text-sm mb-1 whitespace-nowrap">Product Name:</p>
                            <p className="font-DMSans text-sm1 font-normal line-clamp-1 ml-2">
                                Haylou LS02 Smart Watch Global Version
                            </p>
                        </div>
                        <p className="font-Poppins font-medium text-sm mb-1">Rating:
                            <Tippy content="4.00" placement="top">
                                <span className="inline-block ml-2">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="far fa-star"></i>
                                </span>
                            </Tippy>
                        </p>
                        <div className="flex items-center">
                            <p className="font-Poppins font-medium text-sm mb-1">Review:</p>
                            <p className="font-DMSans text-sm1 font-normal line-clamp-1 ml-2">
                                Comfortable wearing and excellent for water repellent.
                                The design of the watch is nice and doesn’t feel cheap. It has a nice weight to it and is big enough on the wrist where it seems nice and sturdy. On top of that, it looks like a nicer end watch until you tap the screen and see that it is a smartwatch.

                                The battery life on this watch is incredible. I have had it for a week since it was ordered to my house and it still is running without me having to charge it yet!

                                This watch has an accurate blood pressure monitor, sleep monitor, counts your steps, alerts you to incoming calls and notifications from any apps including your messages, and is water-resistant.

                                In conclusion for the price, it is an incredible smartwatch if you are looking for a cheaper alternative to the big-name smartwatches. It does everything they can do and then some. It also beats them in battery life. I would rate the overall experience as excellent
                            </p>
                        </div>
                        <p className="font-Poppins font-medium text-sm font-medium">View</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ReviewSummary
