import React from 'react'
import Carousel from '../../ReuseableComponents/Carousel'
import HeroBanner from "../../../Assets/Images/hero_banner.jpg"

const data = [
    {
        image: HeroBanner
    },
    {
        image: HeroBanner
    },
    {
        image: HeroBanner
    },
    {
        image: HeroBanner
    },
]


const MainSlider = () => {
    return (
        <div>
            <Carousel data={data} carouselType="mainCarousel" slidesToShow={1} arrows={false} laptop={1} tab={1} mobile={1} dots={true} autoplay={true} centerMode={false} />
        </div>
    )
}

export default MainSlider
